import React, { useState, useEffect } from "react";
import "./Orders.css";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { loadOrder, resetCurrent } from "../../actions";
const Select_Customer = (props) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState(null);
  useEffect(() => {
    const proposals = props.orders
      .filter(
        (o) =>
          o.status === "Open" &&
          o.customer?.customer_id === props.current.customer?.customer_id
      )
      .map((o) => {
        return {
          name: o.id,
          value: `${o.job_name} [${o.customer?.company}]`,
          selected: false,
          full_data: o,
        };
      });

    setTableData(proposals);
  }, [props.current.customer]);

  const onRowSelect = (id) => {
    let dataCopy = [...tableData];
    dataCopy = dataCopy.map((d, i) => {
      if (id !== i) return { ...d, selected: false };
      return d;
    });

    dataCopy[id].selected = !dataCopy[id].selected;
    setId(dataCopy[id].selected ? dataCopy[id].name : null);
    setTableData(dataCopy);
  };

  const onSubmit = (data) => {
    if (id) {
      props.loadOrder(id);
      setTimeout(() => history.push("/order/summary"), 750);
    } else {
      props.resetCurrent();
      setTimeout(() => history.push("/order/details"), 750);
    }
  };

  return (
    <div className="orders">
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>Create a new order</div>
      </div>
      <div className="content">
        <div className="left centered">
          <button
            className={!id ? "btn action-btn" : "btn secondary-btn"}
            type="submit"
            onClick={onSubmit}
            disabled={id !== null}
          >
            Create New
          </button>
          <button
            className={id ? "btn action-btn" : "btn secondary-btn"}
            type="submit"
            onClick={onSubmit}
            disabled={id === null}
          >
            Edit
          </button>
        </div>
        <div className="right">
          <h2>Proposals for customer {props.current.customer?.company}</h2>
          <p>Select a proposal to edit</p>
          <QueryTable data={tableData} onRowSelect={onRowSelect} />
          {/* TO-DO: Summary  */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    customers: state.customers.list,
    orders: state.orders.list,
  };
};

export default connect(mapStateToProps, { loadOrder, resetCurrent })(
  Select_Customer
);
