import React, { useEffect } from "react";
import "./SubmittedOrders.css";
import { getOrders } from "../../actions";
import { connect } from "react-redux";
const Reports = (props) => {
  useEffect(() => {
    props.getOrders();
  }, []);
  return (
    <div className="home" style={{ marginTop: 0 }}>
      <h3 className="title">Select a table</h3>
      <div className="content" style={{ flexWrap: "wrap" }}>
        <button
          className="btn action-btn"
          onClick={() => (window.location.href = "/reports/submitted")}
        >
          Submitted Orders
        </button>
        <button
          className="btn action-btn"
          onClick={() => (window.location.href = "/reports/open")}
        >
          Open Orders
        </button>
        <button
          className="btn action-btn"
          onClick={() => (window.location.href = "/reports/check-in")}
        >
          Check In
        </button>
        <button
          className="btn action-btn"
          onClick={() => (window.location.href = "/reports/check-out")}
        >
          Check Out
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders.list,
  };
};
export default connect(mapStateToProps, { getOrders })(Reports);
