import React, { useState, useEffect } from "react";
import "./Orders.css";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import { useHistory, Prompt } from "react-router-dom";
import { searchProducts } from "../../api";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  addGarment,
  updateGarment,
  setActiveGarment,
} from "../../actions/ordersActions";

const Select_Garments = (props) => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [garment, setGarment] = useState({});

  useEffect(() => {
    props.setCurrentStep(2);

    if (props.active_garment && props.active_garment.table_data)
      setTableData(props.active_garment.table_data);
  }, []);
  useEffect(() => {
    if (!props.active_garment) props.addGarment(garment);
    else if (props.active_garment && Object.keys(garment).length > 0) {
      const oldSizes = props.active_garment.sizes;
      const newSizes = garment.sizes.map((s) => {
        const found = oldSizes.find((os) => os.qty && os.size === s);
        return found ? found : { size: s, qty: null, price: null };
      });
      props.updateGarment(props.active_garment_id, {
        ...garment,
        sizes: newSizes,
      });
    }
  }, [garment]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onRowSelect = (id) => {
    let dataCopy = [...tableData];
    dataCopy = dataCopy.map((d) => {
      return { ...d, selected: false };
    });

    dataCopy[id].selected = !dataCopy[id].selected;
    setGarment({ ...dataCopy[id].full_data, table_data: tableData });
    setTableData(dataCopy);
  };

  const onSubmit = async (data) => {
    const { distributor, brand, style_number, color } = data;
    try {
      const productsRes = await searchProducts(
        distributor,
        brand,
        style_number,
        color
      );

      if (productsRes.data.error) {
        toast.dark(productsRes.data.error);
        return;
      }
      const tData = productsRes.data.products.map((p, i) => {
        return {
          name: i,
          value: p.description,
          full_data: p,
          selected: false,
        };
      });
      setTableData(tData);
    } catch (e) {
      toast.dark(e.message);
    }
  };

  return (
    <div className="orders">
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => history.goBack()}
          className="btn secondary-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Back
        </button>
        <div>Create a new order</div>
        <button
          onClick={() => {
            if (!props.active_garment) props.addGarment(garment);
            else if (props.active_garment && Object.keys(garment).length > 0) {
              const oldSizes = props.active_garment.sizes;
              const newSizes = garment.sizes.map((s) => {
                const found = oldSizes?.find((os) => os.qty && os.size === s);
                return found ? found : { size: s, qty: null, price: null };
              });
              props.updateGarment(props.active_garment_id, {
                ...garment,
                sizes: newSizes,
              });
            } else {
              props.updateGarment(props.active_garment_id, garment);
            }
            setTimeout(() => history.push("/order/quantities"), 500);
          }}
          className="btn action-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Next
        </button>
      </div>
      {props.active_garment && (
        <div>
          <span>
            Selected Garment:{" "}
            {props.active_garment.distributor === "Manual"
              ? "Manual"
              : props.active_garment.description}
          </span>
          <button
            style={{ width: "32px", padding: 0 }}
            onClick={() => props.setActiveGarment(null)}
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
        </div>
      )}
      <div className="content">
        <div className="left">
          <h2>Select Distributor</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <select {...register("distributor")} style={{ width: "40%" }}>
              <option value="" disabled selected>
                Distributor
              </option>

              <option value="SanMar">Sanmar</option>
              <option value="SS">S and S</option>
            </select>

            <input
              type="text"
              name="brand"
              placeholder="Brand"
              {...register("brand")}
            />
            <input
              type="text"
              name="Style Number"
              placeholder="Style Number"
              {...register("style_number")}
            />
            <input
              type="text"
              name="color"
              placeholder="Color"
              {...register("color")}
            />
            <button className="btn action-btn" type="submit">
              Search
            </button>
            <button
              className="btn secondary-btn"
              type="submit"
              onClick={() => history.push("/order/distributor/manual")}
            >
              Manual Input
            </button>
          </form>
        </div>
        <div className="right">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Results</h2>
          </div>
          <QueryTable data={tableData} onRowSelect={onRowSelect} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    active_garment_id: state.orders.current.active_garment,
    active_garment:
      state.orders.current.garments[state.orders.current.active_garment],
  };
};

export default connect(mapStateToProps, {
  addGarment,
  updateGarment,
  setActiveGarment,
})(Select_Garments);
