import React, { useEffect, useState } from "react";
import "./SubmittedOrders.css";
import { getOrders, loadOrder } from "../../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
const CheckInOut = (props) => {
  const [orderNumber, setOrderNumber] = useState(null);
  const [order, setOrder] = useState(null);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.state?.order_id) {
      loadOrder(location.state.order_id);
    }
  }, [location]);
  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      loadOrder(orderNumber);
    }
  };

  const loadOrder = async (id) => {
    const res = await axios.get("/api/orders/" + id);
    if (res.data.error) {
      toast.dark(res.data.error);
      setOrderNumber("");

      return;
    }

    setOrder(res.data);
  };

  const onSubmit = async () => {
    if (!order) return;
    let sum = 0;
    order.garments.map((g) => {
      g.sizes
        ?.filter((s) => s.qty && s.qty > 0)
        .map((s) => {
          sum += s.qty * s.price;
        });
    });

    await axios.put("/api/orders/" + order.id, {
      reseller_code: props.reseller_code,
      customer_id: order.customer.customer_id,
      ship_to_id: order.address.id,
      details: order.details,
      garments: order.garments,
      shipping_price: order.shipping,
      setup_price: order.setup,
      total_price:
        parseFloat(order.setup) + parseFloat(order.shipping) + parseFloat(sum),
      status: order.progress.status,
      store: order,
    });

    if (isFilledOut()) {
      props.check_out
        ? (order.progress.checked_out = new Date())
        : (order.progress.checked_in = new Date());
      axios.put("/api/orders/batch/progress", { orders: [order] });
    }

    setOrderNumber("");
    setOrder(null);

    toast.dark("Successfully updated garments");
    if (location.state?.order_id) {
      setTimeout(() => history.push("/reports/submitted"), 500);
    }
  };

  const isFilledOut = () => {
    try {
      for (const g of order.garments) {
        for (const s of g.sizes.filter((sz) => sz.qty && sz.qty > 0)) {
          const field = parseInt(
            props.check_out ? s.checked_out : s.checked_in
          );
          if (!field || field === undefined) return false;

          const qty = parseInt(s.qty);
          if (qty && qty > 0 && field < qty) {
            return false;
          }
        }
      }
      return true;
    } catch {
      return false;
    }
  };
  const updateVerified = (garment_id, size_id, val) => {
    const orderCopy = { ...order };

    const size = orderCopy.garments[garment_id].sizes[size_id];
    !props.check_out ? (size.checked_in = val) : (size.checked_out = val);
    setOrder(orderCopy);
  };
  return (
    <div className="home" style={{ marginTop: 0 }}>
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: "0",
          marginBlockStart: "2rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          name="order_no"
          placeholder="Order number"
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
          style={{ width: "20%" }}
          onKeyDown={handleKeyDown}
        />
        <p style={{ marginBlockEnd: "0", marginBlockStart: "0" }}>
          Garment Check {props.check_out ? "Out" : "In"}
        </p>

        {order && (
          <button
            className="btn action-btn"
            onClick={onSubmit}
            style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
          >
            Submit
          </button>
        )}
        {!order && <div />}
      </div>
      {order && (
        <div className="content">
          <div className="query-table reports-table">
            <table>
              <thead>
                <tr>
                  <th>Order Name</th>
                  <th>Garment Style</th>
                  <th>Color</th>
                  <th>Size</th>
                  <th>Ordered</th>
                  <th>Verified</th>
                  {props.check_out && <th>Check Out</th>}

                  <th>Complete</th>
                </tr>
              </thead>
              <tbody>
                {order.garments.map((g, i) => {
                  return g.sizes.map((s, i2) => {
                    if (s.qty && s.qty > 0)
                      return (
                        <tr>
                          <td>
                            {i === 0 && i2 === 0 ? order.details.job_name : ""}
                          </td>
                          <td>
                            {g.brand} {g.style_number}
                          </td>
                          <td>{g.color}</td>
                          <td>{s.size}</td>
                          <td>{s.qty}</td>
                          {props.check_out && <td>{s.checked_in}</td>}
                          {/* {props.check_out && <td>{</td>} */}
                          <td>
                            <input
                              type="number"
                              value={
                                !props.check_out
                                  ? order.garments[i].sizes[i2].checked_in
                                  : order.garments[i].sizes[i2].checked_out
                              }
                              onChange={(e) =>
                                updateVerified(i, i2, e.target.value)
                              }
                              style={{ width: "60%" }}
                            />
                          </td>
                          <td
                            className={
                              s.qty ===
                              (props.check_out ? s.checked_out : s.checked_in)
                                ? "completed"
                                : (props.check_out
                                    ? s.checked_out
                                    : s.checked_in) > 0
                                ? "pending"
                                : ""
                            }
                            style={{ textAlign: "center" }}
                            onClick={() =>
                              updateVerified(
                                i,
                                i2,
                                s.qty ===
                                  (props.check_out
                                    ? s.checked_out
                                    : s.checked_in)
                                  ? ""
                                  : s.qty
                              )
                            }
                          >
                            {/* {JSON.stringify(s)} */}
                            {props.check_out ? s.checked_out : s.checked_in}/
                            {s.qty} (
                            {(
                              ((props.check_out
                                ? s.checked_out
                                : s.checked_in) /
                                s.qty) *
                              100
                            ).toFixed(2) + "%"}
                            )
                          </td>
                        </tr>
                      );
                  });
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders.list,
    current: state.orders.current,
    reseller_code: state.reseller.current.code,
  };
};
export default connect(mapStateToProps, { getOrders, loadOrder })(CheckInOut);
