import {
  GET_ORDERS,
  GET_ERRORS,
  SET_CUSTOMER,
  SET_ADDRESS,
  SET_DETAILS,
  ADD_GARMENT,
  SET_SIZES,
  SET_DESIGNS,
  ADD_PLACEMENT,
  SET_ACTIVE,
  RESET_CURRENT,
  REMOVE_GARMENT,
  UPDATE_GARMENT,
  RESET_SIZING,
  RESET_PLACEMENTS,
  RESET_CUSTOMER,
  SET_SHIPPING,
  SET_SETUP,
  LOAD_ORDER
} from "./types";
import { getOrders as getOrdersAPI, getOrder as getOrderAPI } from "../api";
// Login - get user token
export const getOrders = () => async (dispatch) => {
  try {
    const orders = await getOrdersAPI();
    dispatch({ type: GET_ORDERS, payload: orders.data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const setCustomer = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_CUSTOMER, payload: data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const setDetails = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_DETAILS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const setAddress = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_ADDRESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const setDesigns = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_DESIGNS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const addGarment = (data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_GARMENT, payload: data });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const loadOrder = (id) => async (dispatch) => {
  try {
    const res = await getOrderAPI(id);
    dispatch({ type: LOAD_ORDER, payload: res.data });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};
export const updateGarment = (id, garment) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GARMENT, payload: { id, garment } });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const addSizing = (garment, sizes) => async (dispatch) => {
  try {
    dispatch({ type: SET_SIZES, payload: { garment, sizes } });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const addPlacements = (id, placements) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PLACEMENT, payload: { id, placements } });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const setActiveGarment = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_ACTIVE, payload: id });
  } catch (err) {
    console.log("Err, ", err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const removeGarment = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_GARMENT, payload: id });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};
export const resetCurrent = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_CURRENT });
  } catch (err) {
    console.log("Err, ", err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const resetSizing = (id) => async (dispatch) => {
  try {
    dispatch({ type: RESET_SIZING, payload: id });
  } catch (err) {
    console.log("Err, ", err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const resetPlacements = (id) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PLACEMENTS, payload: id });
  } catch (err) {
    console.log("Err, ", err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const resetCustomer = (id) => async (dispatch) => {
  try {
    dispatch({ type: RESET_CUSTOMER });
  } catch (err) {
    console.log("Err, ", err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const setShipping = (val) => async (dispatch) => {
  try {
    dispatch({ type: SET_SHIPPING, payload: val });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const setSetup = (val) => async (dispatch) => {
  try {
    dispatch({ type: SET_SETUP, payload: val });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};
