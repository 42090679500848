import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer from "../reducers";

const persistConfig = {
  key: "root3",
  storage,
  debug: true,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  let store = createStore(
    persistedReducer,
    undefined,
    composeEnhancers(applyMiddleware(reduxThunk))
  );

  let persistor = persistStore(store);
  return { store, persistor };
};
