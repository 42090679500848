import React, { useState, useEffect } from "react";
import "./Orders.css";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import { useHistory, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { addGarment, updateGarment } from "../../actions/ordersActions";

const Manual_Garments = (props) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues:
      props.active_garment?.distributor === "Manual"
        ? props.active_garment
        : {},
  });
  const onSubmit = (data) => {
    let {front_image, back_image, sleeve_image, other_image, ...remainingData} = data;
    remainingData = {
      ...remainingData,
      images: [front_image, back_image, sleeve_image, other_image].filter((i) => i && i.trim() !== ""),
    };
    props.setCurrentStep(2);
    props.addGarment({
      ...remainingData,
      sizes: [],
    });
  };

  return (
    <div className="orders">
      <Prompt
        when={props.active_garment?.distributor === "Manual"}
        message={async (location, action) => {
          props.updateGarment(props.active_garment_id, getValues());
          return true;
        }}
      />
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => history.goBack()}
          className="btn secondary-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Back
        </button>
        <div>Create a new order</div>
        <button
          onClick={() => {
            handleSubmit(onSubmit)();
            setTimeout(() => history.push("/order/quantities"), 500);
          }}
          className="btn action-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Next
        </button>
      </div>
      <form
        // onSubmit={handleSubmit(onSubmit)}
        className="content single-col"
        style={{ flexDirection: "row" }}
      >
        <div className="left">
          <h2>Enter details for garment</h2>
          <input
            type="hidden"
            name="distributor"
            value="Manual"
            {...register("distributor")}
          />
          <input
            type="text"
            name="brand"
            placeholder="Brand"
            {...register("brand")}
          />
          <input
            type="text"
            name="Style Number"
            placeholder="Style Number"
            {...register("style_number")}
          />
          <input
            type="text"
            name="color"
            placeholder="Color"
            {...register("color")}
          />
          <input
            type="text"
            name="title"
            placeholder="Description"
            {...register("title")}
          />
        </div>
        <div className="right">
          <h2>Enter image links for garment</h2>

          <div>
            <input
              type="text"
              name="name"
              placeholder="Image name #1"
              {...register("front_image")}
            />
            <i class="fa fa-upload"></i>
          </div>

          <div>
            <input
              type="text"
              name="name_2"
              placeholder="Image name #2"
              {...register("back_image")}
            />

            <i class="fa fa-upload"></i>
          </div>
          <div>
            <input
              type="text"
              name="name_3"
              placeholder="Image name #3"
              {...register("sleeve_image")}
            />
            <i class="fa fa-upload"></i>
          </div>
          <div>
            <input
              type="text"
              name="name_4"
              placeholder="Image name #4"
              {...register("other_image")}
            />
            <i class="fa fa-upload"></i>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    active_garment_id: state.orders.current.active_garment,
    active_garment:
      state.orders.current.garments[state.orders.current.active_garment],
  };
};

export default connect(mapStateToProps, { addGarment, updateGarment })(
  Manual_Garments
);
