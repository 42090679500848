const axios = require("axios");

export const searchProducts = (distributor, brand, style_number, color) => {
  return axios.post("/api/garments/search", {
    distributor,
    brand,
    style_number,
    color,
  });
};
