import React, { useState, useEffect } from "react";
import QueryTable from "../../components/QueryTable/QueryTable";
import "./Home.css";
import { connect } from "react-redux";
import {
  getOrders,
  getResellers,
  getCustomers,
  getDesigns,
  loadOrder,
} from "../../actions";
import { useHistory } from "react-router-dom";

const Home = (props) => {
  const [recentProposals, setRecentProposals] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [submittedOrders, setSubmittedOrders] = useState([]);
  const [pendingDesigns, setPendingDesigns] = useState([]);
  const history = useHistory();

  useEffect(() => {
    props.getCustomers();
    props.getResellers();
    props.getDesigns();
    props.getOrders();
  }, []);
  useEffect(() => {
    const orders = props.orders
      .filter((o) => o.status === "Pending")
      .map((o) => {
        return {
          name: o.id,
          value: `${o.job_name} [${o.customer?.company}]`,
          selected: false,
          full_data: o,
        };
      });
    const proposals = props.orders
      .filter((o) => o.status === "Open")
      .map((o) => {
        return {
          name: o.id,
          value: `${o.job_name} [${o.customer?.company}]`,
          selected: false,
          full_data: o,
        };
      });
    const submitted = props.orders
      .filter((o) => o.status === "Submitted")
      .map((o) => {
        return {
          name: o.id,
          value: `${o.job_name} [${o.customer?.company}]`,
          selected: false,
          full_data: o,
        };
      });
    const designs = props.designs
      .filter((d) => d.status === "Pending")
      .map((d) => {
        return {
          name: d.id,
          value: d.art_id ?? "Design #" + d.id,
          selected: false,
          full_data: d,
        };
      });

    setPendingOrders(orders);
    setRecentProposals(proposals);
    setPendingDesigns(designs);
    setSubmittedOrders(submitted);
  }, [props.orders, props.designs]);

  return (
    <div className="home">
      <h3 className="title">Welcome to The Program Dashboard</h3>
      <div className="tables" style={{ maxHeight: "60vh", overflowY: "scroll", width:"90%"}}>
        <QueryTable
          data={recentProposals}
          onRowSelect={(id) => {
            props.loadOrder(recentProposals[id].name);
            setTimeout(
              () => history.push("/order/summary"),
              500
            );
          }}
          title="Recent Proposals"
        />
        <QueryTable
          data={pendingOrders}
          onRowSelect={(id) => {
            props.loadOrder(pendingOrders[id].name);
            setTimeout(
              () => history.push("/proof/" + pendingOrders[id].name),
              500
            );
          }}
          title="Pending Orders"
        />

        <QueryTable
          data={submittedOrders}
          onRowSelect={(id) => {
            //admin only
            props.loadOrder(submittedOrders[id].name);
            setTimeout(() => history.push("/order/summary"), 500);
          }}
          title="Submitted Orders"
        />
        <QueryTable
          data={pendingDesigns}
          onRowSelect={(id) =>
            history.push("/designs/" + pendingDesigns[id].name)
          }
          title="Pending Designs"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { orders: state.orders.list, designs: state.designs.list };
};

export default connect(mapStateToProps, {
  getOrders,
  getDesigns,
  getResellers,
  getCustomers,
  loadOrder,
})(Home);
