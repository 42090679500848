import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import logo from "../../logo.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setActiveGarment,
  resetCurrent,
  resetSizing,
  resetPlacements,
  removeGarment,
  loadOrder,
  setCustomer,
  getResellers,
  getCustomers,
  getOrders,
  setReseller,
  resetCustomer,
} from "../../actions";
import { setResellerAuth, setCustomerAuth } from "../../helpers/setAuthToken";

const Sidebar = (props) => {
  const [active, setActive] = useState("home");
  useEffect(() => {
    if (!props.reseller.current?.code) return;

    setResellerAuth(props.reseller.current?.code);

    // props.resetCurrent();
    props.getCustomers();
    props.getResellers();
    props.getOrders();
  }, [props.reseller.current]);

  useEffect(() => {
    if (
      props.orders.current.customer &&
      props.orders.current.customer.customer_id
    )
      setCustomerAuth(props.orders.current.customer.customer_id);
  }, [props.orders.current]);

  return (
    <div className="sidebar">
      <img alt="Logo" className="logo" src={logo} />
      <ul>
        <li className={active === "home" ? "active" : ""}>
          <Link onClick={() => setActive("home")} to="/">
            Home
          </Link>
        </li>
        <li className={active === "order" ? "active" : ""}>
          <Link onClick={() => setActive("order")} to="/order">
            Order
          </Link>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </li>
        <li className={active === "designs" ? "active" : ""}>
          <Link onClick={() => setActive("designs")} to="/designs">
            Designs
          </Link>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </li>
        {/* <li>
          <a href="/">Customers</a>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </li> */}

        <li className={active === "customers" ? "active" : ""}>
          <Link onClick={() => setActive("customers")} to="/customers">
            Customers
          </Link>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </li>
        <li className={active === "resellers" ? "active" : ""}>
          <Link onClick={() => setActive("resellers")} to="/resellers">
            Setup
          </Link>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </li>
        <li className={active === "reports" ? "active" : ""}>
          <Link onClick={() => setActive("reports")} to="/reports">
            Reports
          </Link>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </li>
        {/* <li>
          <a href="/">Setup</a>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </li> */}
      </ul>
      <div className="nav-tree">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label>Reseller</label>
          <select
            className="reseller-dropdown"
            // value={props.reseller.current.code}
            onChange={(e) => {
              props.setReseller(
                e.target.value !== "ALL"
                  ? props.reseller.list.find((r) => r.code == e.target.value)
                  : { code: "ALL", company: "ALL" }
              );
              props.resetCurrent();
              props.resetCustomer();
              setTimeout(() => window.location.reload(), 500);
            }}
          >
            <option selected disabled>
              {props.reseller.current?.code
                ? props.reseller.current.code +
                  " - " +
                  props.reseller.current.company
                : "Please select one"}
            </option>

            <option value="ALL">ALL - ALL</option>

            {props.reseller.list?.map((o, i) => {
              return (
                <option value={o.code}>
                  {o.code} - {o.company}
                </option>
              );
            })}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label>Customer</label>
          <input
            type="text"
            style={{ color: "#fff", width: "70%" }}
            disabled
            readOnly
            value={props.orders.current.customer?.company}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label>Order</label>
          <input
            type="text"
            style={{ color: "#fff", width: "70%" }}
            disabled
            readOnly
            value={
              props.orders.current.id
                ? props.orders.current.id +
                  " - " +
                  props.orders.current.details.job_name
                : "None"
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps, {
  setActiveGarment,
  removeGarment,
  resetCurrent,
  resetSizing,
  resetPlacements,
  loadOrder,
  setCustomer,
  getResellers,
  getCustomers,
  getOrders,
  setReseller,
  resetCustomer,
})(Sidebar);
