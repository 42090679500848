import { combineReducers } from "redux";
import errorsReducer from "./errorsReducer";
import ordersReducer from "./ordersReducer";
import resellerReducer from "./resellerReducer";
import customersReducer from "./customersReducer";
import designsReducer from "./designsReducer";
export default combineReducers({
  orders: ordersReducer,
  errors: errorsReducer,
  customers: customersReducer,
  reseller: resellerReducer,
  designs: designsReducer,
  //   persisted: persistedReducer,
});
