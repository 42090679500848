/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef, createRef } from "react";
import Moveable from "react-moveable";
import { useScreenshot } from "use-react-screenshot";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { addPlacements, setActiveGarment, getDesigns } from "../../actions";
import Modal from "react-modal";
import axios from "axios";

const Designs_Placement = (props) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  Modal.setAppElement("#root");

  const [activeGarment, setActiveGarment] = useState(props.active_garment);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [flipGarment, setFlipGarment] = useState(false);
  const [index, setIndex] = useState(0);
  const [activeImage, setActiveImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  const [addedDesigns, setAddedDesigns] = useState([]);

  const [target, setTarget] = useState();
  const [garmentTarget, setGarmentTarget] = useState(".garment-container");
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);
  const ref_mini = createRef(null);
  let [image, takeScreenshot] = useScreenshot();

  const [decorationMethod, setDecorationMethod] = useState("default");
  const [placementName, setPlacementName] = useState("default");

  useEffect(() => {
    setShowMenu(activeImage === null);
  }, [activeImage]);
  const getImage = () => {
    if (!target) {
      image = null;
      let designsCopy = [...addedDesigns];
      if (index >= designsCopy.length) {
        designsCopy = [
          ...designsCopy,
          { done: true, added: false, image: activeImage },
        ];
      } else if (!designsCopy[index].added) {
        designsCopy[index] = { done: true, added: false, image: activeImage };
      }
      setAddedDesigns(designsCopy);
      nextImage();
      return;
    }
    if (
      target &&
      (decorationMethod === "default" || placementName === "default")
    ) {
      alert("You must pick a decoration method and a placement name!");
      return;
    }
    setGarmentTarget(null);

    window.document
      .querySelectorAll(".moveable-control-box")
      .forEach((e) => (e.style.display = "none"));
    window.scrollTo(0, 0);

    takeScreenshot(ref.current ? ref.current : ref_mini.current);

    window.document
      .querySelectorAll(".moveable-control-box")
      .forEach((e) => (e.style.display = "block"));
  };

  useEffect(() => {
    if (!garmentTarget) setGarmentTarget(".garment-container");
  }, [garmentTarget]);

  useEffect(() => {
    if (index === activeGarment.images.length) setShowMenu(true);
    else if (index > 2 && index === activeGarment.images.length - 1) {
      setFlipGarment(true);
    } else {
      setFlipGarment(false);
      setActiveImage(`/api/image?url=${activeGarment?.images[index]}`);
      setPreviewImage(`/api/image?url=${activeGarment?.images[index]}`);
    }
  }, [index]);

  const nextImage = () => {
    setIndex(index + 1);
    setIsOpen(false);
    setTarget(null);
    setDecorationMethod("default");
    setPlacementName("default");
  };

  const newTarget = (img) => {
    if (!img.class) return;

    const el = window.document.querySelector("." + img.class);

    if (!el) return;
    el.style.left = 0;
    el.style.top = 0;
    el.style.transform = "";

    if (img.width) {
      el.style.transform = `translate(${img.x}px, ${img.y}px)`;

      if (img.rotation > 0) el.style.transform += ` rotate(${img.rotation}deg)`;

      el.style.width = img.width + "px";
      el.style.height = img.height + "px";
    }

    setTarget(el);
  };
  const onManipulated = (rect) => {
    const addedDesignsCopy = [...addedDesigns];
    const newAddedDesigns = addedDesignsCopy.map((d, i) => {
      if (i === index) {
        d.x = Math.round(rect.left);
        d.y = Math.round(rect.top);
        d.width = Math.round(rect.offsetWidth);
        d.height = Math.round(rect.offsetHeight);
        d.rotation = Math.round(rect.rotation);
      }
      return d;
    });
    setAddedDesigns(newAddedDesigns);
  };

  const onGarmentRotate = (rect) => {
    const addedDesignsCopy = [...addedDesigns];
    const newAddedDesigns = addedDesignsCopy.map((d, i) => {
      if (i === index) {
        d.garment_rotation = Math.round(rect.rotation);
        d.garment_transform = ref.current.style.transform;
      }
      return d;
    });
    setAddedDesigns(newAddedDesigns);
  };

  useEffect(() => {
    props.setCurrentStep(3);
    props.getDesigns();
    if (activeGarment && activeGarment.images.length > 2) {
      setActiveGarment({
        ...activeGarment,
        images: [
          ...activeGarment.images,
          activeGarment.images[activeGarment.images.length - 1],
        ],
      });
    }
    if (activeGarment && activeGarment.placements) {
      if (activeGarment.placements.length === 0) return;
      setAddedDesigns(activeGarment.placements);
    }
  }, []);

  const loadDesign = (id) => {
    if (!ref.current || addedDesigns.length === 0) return;

    let placements = activeGarment.placements;

    if (!placements || id >= placements.length || !placements[id].added) {
      setDecorationMethod("default");
      setPlacementName("default");
      ref.current.style.transform = ref.current.style.transform
        .split("rotate")[0]
        ?.trim();
      return;
    }

    let newDesigns = addedDesigns.map((a, i) => {
      return { ...a, done: i !== id };
    });

    newDesigns[id] = { ...placements[id], done: false };

    ref.current.style.transform = newDesigns[id].garment_transform;
    setGarmentTarget(null); //reset
    setAddedDesigns(newDesigns);
    setDecorationMethod(newDesigns[id].decoration_method);
    setPlacementName(newDesigns[id].name);
  };

  const uploadImage = async (img) => {
    const body = JSON.stringify({ image: img });
    const res = await axios.post("/api/placements/upload", body, {
      headers: { "content-type": "application/json" },
    });

    return res.data.filename;
  };
  const onNewImage = async () => {
    let newDesigns = [...addedDesigns];
    console.log(image);
    if (index < newDesigns.length) {
      const filename = await uploadImage(image);
      newDesigns[index] = {
        ...newDesigns[index],
        added: newDesigns[index].added || target !== null,
        done: true,
        name: placementName,
        decoration_method: decorationMethod,
        image: `/placements/${filename}`,
      };

      if (newDesigns[index].rotation > 0 && target) {
        const regex = /translate\((.*?)px, (.*?)px/gm;
        const matches = regex.exec(target.style.transform);
        newDesigns[index].x = matches[1];
        newDesigns[index].y = matches[2];
      }
    }

    setAddedDesigns([...newDesigns]);
    nextImage();
  };
  useEffect(() => {
    if (image) {
      onNewImage();
    }
  }, [image]);

  useEffect(() => {
    console.log("AD", addedDesigns);
    console.log("Target", target);
  }, [addedDesigns]);
  const history = useHistory();

  return (
    <div className="orders" style={{ height: "100%" }}>
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => history.goBack()}
          className="btn secondary-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Back
        </button>
        <div>Create a new order</div>
        {!showMenu && (
          <button
            onClick={() => {
              getImage();
            }}
            className="btn action-btn"
            style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
          >
            {target ? "Add Selected" : "Skip"}
          </button>
        )}
        {showMenu && <div></div>}
      </div>
      {/* <p>{JSON.stringify(this.state)}</p> */}
      <div className="content" style={{ textAlign: "center" }}>
        <div className="left">
          <h2>Designs</h2>
          <div className="list">
            {props.current.designs.map((img, i) => {
              return (
                <img
                  key={img.id}
                  src={img.url}
                  onClick={() => {
                    if (target) return;

                    const myImg = {
                      id: img.id,
                      design_id: img.id,
                      url: `/api/designs/${img.id}.png`,
                      class: "target-" + i,
                      art_id: img.art_id,
                      art_width: img.width,
                      art_height: img.height,
                      x: null,
                      y: null,
                      width: window.document.querySelector(".preview-icon-" + i)
                        .width,
                      height: window.document.querySelector(
                        ".preview-icon-" + i
                      ).height,
                      rotation: 0,
                      done: false,
                    };

                    if (addedDesigns[index]) {
                      const newDesigns = [...addedDesigns];
                      newDesigns[index] = myImg;
                      setAddedDesigns([...newDesigns]);
                    } else setAddedDesigns([...addedDesigns, myImg]);
                    setIsOpen(true);
                    // newTarget(myImg);
                  }}
                  className={"preview-icon preview-icon-" + i}
                />
              );
            })}
          </div>
        </div>
        <div className="center">
          {!showMenu && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <h2 className="studio-title">Studio</h2>

              <div
                className={
                  flipGarment
                    ? "garment-container-mini flip-garment"
                    : "garment-container-mini"
                }
                style={{
                  background: `url('${previewImage}') center top no-repeat`,
                }}
                ref={ref_mini}
              ></div>
            </div>
          )}
          {!showMenu && (
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={() => {
                if (
                  activeGarment.placements &&
                  addedDesigns[index].id === activeGarment.placements[index]?.id
                ) {
                  loadDesign(index);
                  newTarget(activeGarment.placements[index]);
                } else {
                  newTarget(addedDesigns[index]);
                }
              }}
              onRequestClose={() => {
                setIsOpen(false);
                setTarget(null);
                if (
                  activeGarment?.placements &&
                  activeGarment.placements[index].added
                ) {
                  const designsCopy = [...addedDesigns];
                  designsCopy[index] = activeGarment.placements[index];
                  setAddedDesigns(designsCopy);
                }
              }}
              style={customStyles}
              // ref={ref}
              contentLabel="Example Modal"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  className="buttons"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <button
                    className="btn active-btn"
                    style={{ width: "42px", height: "42px", padding: 0 }}
                    onClick={() => {
                      const newDesigns = [...addedDesigns];
                      newDesigns[index] = {
                        added: false,
                        done: true,
                        image: activeImage,
                      };
                      setAddedDesigns(newDesigns);
                      setDecorationMethod("default");
                      setPlacementName("default");
                      setTarget(null);
                    }}
                  >
                    <i className="fa fa-trash fa-2x" aria-hidden="true"></i>
                  </button>
                  <button
                    onClick={() => {
                      getImage();
                    }}
                    className="btn action-btn"
                    style={{
                      width: "42px",
                      height: "42px",
                      padding: 0,
                      color: "#fff",
                    }}
                  >
                    <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <Moveable
                className="garment-moveable"
                target={garmentTarget}
                // container={container}
                origin={false}
                draggable={false}
                resizable={false}
                rotatable={true}
                keepRatio={true}
                throttleResize={0}
                zoom={1}
                onRotate={(e) => {
                  e.target.style.transform = e.transform;
                }}
                onRotateEnd={(e) => {
                  onGarmentRotate(e.moveable.getRect());
                }}
                padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
              />
              <div
                className={
                  flipGarment
                    ? "garment-container flip-garment"
                    : "garment-container"
                }
                style={{
                  background: `url('${activeImage}') center top no-repeat`,
                }}
                ref={ref}
              >
                {addedDesigns
                  .filter((d) => !d.done)
                  .map((d) => (
                    <img
                      key={d.id}
                      src={d.url}
                      className={d.class}
                      onClick={() => !target && newTarget(d)}
                      style={{
                        position: "absolute",
                        width: "128px",
                        height: "auto",
                      }}
                    />
                  ))}

                <Moveable
                  target={target}
                  // container={container}
                  origin={false}
                  draggable={true}
                  resizable={true}
                  rotatable={true}
                  keepRatio={true}
                  throttleResize={0}
                  renderDirections={[
                    "nw",
                    "n",
                    "ne",
                    "w",
                    "e",
                    "sw",
                    "s",
                    "se",
                  ]}
                  // edge={true}
                  zoom={1}
                  onRotateStart={(e) => {
                    // e.set(frame.rotate);
                  }}
                  onRotate={(e) => {
                    target.style.transform = e.transform;
                  }}
                  padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                  onDrag={(e) => {
                    target.style.transform = e.transform;
                  }}
                  onDragEnd={(e) => {
                    onManipulated(e.moveable.getRect());
                  }}
                  onResizeEnd={(e) => {
                    onManipulated(e.moveable.getRect());
                  }}
                  onRotateEnd={(e) => {
                    onManipulated(e.moveable.getRect());
                  }}
                  onResize={(e) => {
                    e.target.style.width = `${e.width}px`;
                    e.target.style.height = `${e.height}px`;
                    e.target.style.transform = e.transform;
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Decoration method:</span>
                <select
                  value={decorationMethod}
                  onChange={(e) => setDecorationMethod(e.target.value)}
                >
                  <option value="default" disabled selected>
                    Please select one
                  </option>
                  <option value="Screen Print">Screen Print</option>
                  <option value="Embroidery">Embroidery</option>
                  <option value="Heat Transfer">Heat Transfer</option>
                  <option value="Dye Sublimation">Dye Sublimation</option>
                  <option value="Digital (Direct To Garment)">
                    Digital (Direct To Garment)
                  </option>
                </select>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Placement name:</span>
                <select
                  value={placementName}
                  onChange={(e) => setPlacementName(e.target.value)}
                >
                  {/* TO-DO: Fetch from database */}
                  <option value="default" disabled selected>
                    Please select one
                  </option>
                  <option value="Full Front">Full Front</option>
                  <option value="Full Back">Full Back</option>
                  <option value="Left Chest">Left Chest</option>
                  <option value="Left Sleeve">Left Sleeve</option>
                  <option value="Right Sleeve">Right Sleeve</option>
                  <option value="Locker Patch">Locker Patch</option>
                  <option value="Front Leg">Front Leg</option>
                  <option value="Front Thigh">Front Thigh</option>
                  <option value="Side Leg">Side Leg</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </Modal>
          )}
          {showMenu && (
            <div
              className="centered"
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <button
                className="btn action-btn"
                onClick={() => {
                  props.addPlacements(
                    props.current.active_garment,
                    addedDesigns
                  );
                  props.setActiveGarment(null);
                  setTimeout(() => history.push("/order/distributor"), 500);
                }}
              >
                Add Another Garment
              </button>
              <button
                className="btn secondary-btn"
                onClick={() => {
                  props.addPlacements(
                    props.current.active_garment,
                    addedDesigns
                  );
                  setTimeout(() => history.push("/order/summary"), 500);
                }}
              >
                Go to summary
              </button>
            </div>
          )}
        </div>
        <div className="right">
          <h2>Garment Images Added</h2>
          <div className="list" style={{ height: "80%" }}>
            {activeGarment?.images.map((image, i) => console.log(image))}
            {activeGarment?.images.map((image, i) => (
              <img
                className={
                  i === activeGarment.images.filter((f) => f).length - 1 &&
                  ((addedDesigns[i] && !addedDesigns[i].image) ||
                    !addedDesigns[i])
                    ? "flip-garment"
                    : ""
                }
                src={
                  addedDesigns[i] && addedDesigns[i].image
                    ? addedDesigns[i].image.replace(
                        "/api/placements",
                        "/placements"
                      )
                    : `/api/image?url=${image}`
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    active_garment:
      state.orders.current.garments[state.orders.current.active_garment],
  };
};
export default connect(mapStateToProps, {
  addPlacements,
  setActiveGarment,
  getDesigns,
})(Designs_Placement);
