import { GET_RESELLERS, SET_RESELLER } from "../actions/types";

const initialState = {
  current: {
    code: null,
    company: null,
    salesperson_id: 0,
    address_id: 0,
    contact_1: null,
    contact_2: null,
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    zip: null,
  },
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RESELLERS:
      return {
        ...state,
        list: action.payload,
      };
    case SET_RESELLER:
      return { ...state, current: action.payload };
    default:
      return state;
  }
}
