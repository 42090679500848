export const GET_ORDERS = "GET_ORDERS";
export const GET_ERRORS = "GET_ERRORS";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_DETAILS = "SET_DETAILS";
export const ADD_GARMENT = "ADD_GARMENT";
export const UPDATE_GARMENT = "UPDATE_GARMENT";
export const SET_SIZES = "SET_SIZES";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_DESIGNS = "GET_DESIGNS";
export const SET_DESIGNS = "SET_DESIGNS";
export const ADD_PLACEMENT = "ADD_PLACEMENT";
export const SET_ACTIVE = "SET_ACTIVE";
export const RESET_CURRENT = "RESET_CURRENT";
export const REMOVE_GARMENT = "REMOVE_GARMENT";
export const RESET_SIZING = "RESET_SIZING";
export const RESET_PLACEMENTS = "RESET_PLACEMENTS";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const SET_SHIPPING = "SET_SHIPPING";
export const SET_SETUP = "SET_SETUP";
export const LOAD_ORDER = "LOAD_ORDER";
export const GET_RESELLERS = "GET_RESELLERS";
export const SET_RESELLER = "SET_RESELLER";
