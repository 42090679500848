import { GET_CUSTOMERS, GET_ERRORS } from "./types";
import { getCustomers as getCustomersAPI } from "../api";
export const getCustomers = () => async (dispatch) => {
  try {
    const res = await getCustomersAPI();
    dispatch({ type: GET_CUSTOMERS, payload: res.data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
