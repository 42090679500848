import React, { useState, useEffect } from "react";
import "./Orders.css";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import SizeList from "../../components/SizeList/SizeList";
import { useHistory, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { addSizing } from "../../actions/ordersActions";

const Select_Sizes = (props) => {
  const { active_garment } = props;
  const [sizes, setSizes] = useState([]);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    console.log(active_garment);
    const tempSizes = active_garment?.sizes?.map((m) => {
      return { size: m.size ?? m, qty: m.qty, price: m.price };
    });
    setSizes(tempSizes);
  }, []);

  const onSubmit = (e) => {
    // nextStep();
    // e.preventDefault();
  };
  const onNewRow = (e) => {
    e.preventDefault();
    setSizes([...sizes, { size: "" }]);
  };
  return (
    <div className="orders">
      <Prompt
        when={true}
        message={async (location, action) => {
          props.addSizing(active_garment, sizes);
          return true;
        }}
      />
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => history.goBack()}
          className="btn secondary-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Back
        </button>
        <div>Create a new order</div>
        <button
          onClick={() => {
            props.addSizing(active_garment, sizes);
            setTimeout(() => history.push("/order/placements"), 500);
          }}
          className="btn action-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Next
        </button>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="content finalize-form"
        style={{ flexDirection: "row" }}
      >
        <div className="left">
          <h2>Size List</h2>
          <SizeList sizes={sizes} setSizes={setSizes} onNewRow={onNewRow} />
        </div>
        <div
          className="right"
          style={{
            flex: 2,
            borderLeft: "1px solid black",
            textIndent: "15px",
          }}
        >
          <h2>Images found</h2>
          <div className="images-found">
            {active_garment?.images.map((i) => {
              return <img src={i} alt="Garment" />;
            })}
          </div>
          <h2>Comments</h2>
          {/* <QueryTable count={1} /> */}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    active_garment:
      state.orders.current.active_garment >= 0
        ? state.orders.current.garments[state.orders.current.active_garment]
        : null,
  };
};

export default connect(mapStateToProps, { addSizing })(Select_Sizes);
