import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setActiveGarment,
  resetCurrent,
  removeGarment,
  setShipping,
  setSetup,
} from "../../actions";
import "./Summary.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
const Summary = (props) => {
  const { customer, details, address, garments, designs } = props.current;
  const [apparelPrice, setApparelPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [done, setDone] = useState(false);
  const history = useHistory();
  useEffect(() => {
    props.setCurrentStep && props.setCurrentStep(4);

    let sum = 0;
    garments.map((g) => {
      {
        g.sizes
          ?.filter((s) => s.qty && s.qty > 0)
          .map((s) => {
            sum += s.qty * s.price;
          });
      }
    });
    setApparelPrice(sum);
  }, []);

  useEffect(() => {
    setTotalPrice(
      parseFloat(props.current.setup) +
        parseFloat(props.current.shipping) +
        parseFloat(apparelPrice)
    );
  }, [apparelPrice, props.current.shipping, props.current.setup]);

  const getDesignName = (id) => {
    const design = designs.find((d) => d.id === id);
    if (design && design.art_id) return design.art_id;
    else if (design) return "Design #" + id;

    return "Unknown design";
  };

  const getDesign = (id) => {
    const design = designs.find((d) => d.id === id);

    if (design && design.full_data) return design.full_data;
    else if (design) return design;

    return null;
  };

  return (
    <div className="content" style={{ marginTop: "10%" }}>
      <div
        className="left"
        style={{
          flex: 2,
          borderRight: "1px solid black",
          padding: "2% 5%",
          maxHeight: "650px",
          overflow: "auto",
        }}
      >
        {/*  Header */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "3%",
            borderBottom: "3px solid black",
          }}
        >
          <Link
            style={{
              fontSize: "16px",
              textDecoration: "none",
              color: "inherit",
            }}
            to="/order/details"
          >
            <p>{customer.company}</p>
            <p>PO Number: {details.po_number}</p>
            <p>Shipping Via: {details.shipping_method}</p>
            <p>Order Date: {new Date().toLocaleDateString()}</p>
            <p>
              Requested By:{" "}
              {new Date(details.requested_date).toLocaleDateString()}
            </p>
          </Link>

          <Link
            style={{
              fontSize: "16px",
              textDecoration: "none",
              color: "inherit",
            }}
            to="/order/details"
          >
            <p>{address.company}</p>
            <p>{address.address_1}</p>
            <p>
              {address.city}, {address.state} {address.zip}
            </p>
          </Link>
        </div>
        <div
          className="summary"
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
          }}
        >
          {!props.approve_proof && (
            <button
              onClick={() => {
                props.setActiveGarment(null);
                setTimeout(() => history.push("/order/distributor"), 500);
              }}
              className="btn secondary-btn"
              style={{ width: "30%", padding: "5px", alignSelf: "flex-end" }}
            >
              Add another garment
            </button>
          )}
          {garments.map((g, i) => {
            return (
              <ul>
                <li>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                      // to="/order/distributor"
                      onClick={() => {
                        props.setActiveGarment(i);

                        setTimeout(
                          () =>
                            g.distributor !== "Manual"
                              ? history.push("/order/distributor")
                              : history.push("/order/distributor/manual"),
                          500
                        );
                      }}
                    >
                      {g.title
                        ? g.title?.replace(g.style_number, "")
                        : g.brand + " " + g.style_number}
                      {" - " + g.color}
                    </div>
                    {!props.approve_proof && (
                      <button
                        style={{
                          width: "32px",
                          height: "32px",
                          padding: 0,
                          margin: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => props.removeGarment(i)}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    )}
                  </div>
                </li>
                <ul>
                  {g.sizes?.filter((s) => s.qty).length === 0 ? (
                    <li>
                      <div
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: "1rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          props.setActiveGarment(i);
                          setTimeout(
                            () => history.push("/order/quantities"),
                            500
                          );
                        }}
                      >
                        No sizes selected
                      </div>
                    </li>
                  ) : (
                    g.sizes
                      ?.filter((s) => s.qty)
                      .map((s) => {
                        return (
                          <li>
                            <div
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: "1rem",
                                cursor: "pointer",
                                justifyContent: "space-between",
                                display: "flex",
                                width: "80%",
                              }}
                              onClick={() => {
                                props.setActiveGarment(i);
                                setTimeout(
                                  () => history.push("/order/quantities"),
                                  500
                                );
                              }}
                            >
                              <div>
                                {s.qty}x Size {s.size}
                              </div>
                              <div>
                                {s.price && s.price > 0 && "[$" + s.price + "]"}
                              </div>
                            </div>
                          </li>
                        );
                      })
                  )}
                  <ul>
                    {g.placements?.map((p) => {
                      if (p.added)
                        return (
                          <li>
                            <div
                              style={{
                                fontSize: "0.9rem",
                                margin: 0,
                                padding: 0,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                props.setActiveGarment(i);
                                setTimeout(
                                  () => history.push("/order/placements"),
                                  500
                                );
                              }}
                            >
                              {getDesignName(p.id)} on {p.name} at {p.x},{p.y} (
                              {getDesign(p.id)?.width}"x
                              {getDesign(p.id)?.height}")
                            </div>
                          </li>
                        );
                      else
                        return (
                          <li>
                            <div
                              style={{
                                fontSize: "0.9rem",
                                margin: 0,
                                padding: 0,
                              }}
                              to="/order/placements"
                              onClick={() => {
                                props.setActiveGarment(i);
                                setTimeout(
                                  () => history.push("/order/placements"),
                                  500
                                );
                              }}
                            >
                              Missing placement
                            </div>
                          </li>
                        );
                    })}
                  </ul>
                </ul>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  {g.placements?.map((p) => {
                    return (
                      <img
                        src={
                          p.base64 ??
                          p.image
                            ?.replace("/api/placements", "/placements")
                            ?.replace("/api/image?url=", "")
                        }
                        width="100px"
                        alt="Preview"
                      />
                    );
                  })}
                </div>
              </ul>
            );
          })}

          {details.instructions && (
            <div>
              <strong>Instructions: </strong>
              {details.instructions}
            </div>
          )}
        </div>
      </div>
      {props.approve_proof && !props.current.progress?.proof_approved && (
        <div className="right" style={{ padding: "0 5%" }}>
          <button
            className="btn action-btn"
            onClick={async () => {
              try {
                await axios.put("/api/orders/" + props.current.id, {
                  reseller_code: props.reseller_code,
                  customer_id: props.current.customer.customer_id,
                  ship_to_id: props.current.address.id,
                  details: props.current.details,
                  garments: props.current.garments,
                  shipping_price: props.current.shipping,
                  setup_price: props.current.setup,
                  total_price: totalPrice,
                  status: "Approved",
                  store: props.approve_data,
                });
                await axios.put("/api/orders/batch/progress", {
                  orders: [props.approve_data],
                });

                setTimeout(() => history.push("/reports/submitted"), 500);
                // setDone(true);
              } catch (e) {
                alert(JSON.stringify(e));
              }
            }}
          >
            Approve Proof
          </button>
        </div>
      )}

      {!props.approve_proof && (
        <div className="right" style={{ padding: "0 5%" }}>
          <h2>Charges</h2>
          <div style={{ textAlign: "left" }}>
            <p>Apparel: ${apparelPrice.toFixed(2)}</p>
            <label>Set up price:</label>
            <input
              type="text"
              placeholder="Set up"
              style={{ width: "100%", margin: "10px 0" }}
              value={props.current.setup}
              onChange={(e) => props.setSetup(e.target.value)}
            />
            <label>Shipping price:</label>

            <input
              type="text"
              placeholder="Shipping"
              style={{ width: "100%", margin: "10px 0" }}
              value={props.current.shipping}
              onChange={(e) => props.setShipping(e.target.value)}
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Total: ${totalPrice.toFixed(2)}</p>
          {/* TO-DO: Make sure all designs are Pending */}
          {props.current.id &&
            props.current.progress.status === "Submitted" &&
            !done && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  className="btn action-btn"
                  onClick={async () => {
                    await axios.put("/api/orders/" + props.current.id, {
                      reseller_code: props.reseller_code,
                      customer_id: props.current.customer.customer_id,
                      ship_to_id: props.current.address.id,
                      details: props.current.details,
                      garments: props.current.garments,
                      shipping_price: props.current.shipping,
                      setup_price: props.current.setup,
                      total_price: totalPrice,
                      status: "Pending",
                      store: { ...props.current, status: "Pending" },
                    });
                    await axios.put("/api/orders/batch/progress", {
                      orders: [
                        {
                          ...props.current,
                          progress: {
                            ...props.current.progress,
                            proof_sent: new Date(),
                          },
                        },
                      ],
                    });

                    setDone(true);
                  }}
                >
                  Mark as Pending
                </button>
              </div>
            )}
          {props.current.id && props.reseller_code === "ALL" && !done && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                className="btn action-btn"
                onClick={async () => {
                  await axios.put("/api/orders/" + props.current.id, {
                    reseller_code: props.reseller_code,
                    customer_id: props.current.customer.customer_id,
                    ship_to_id: props.current.address.id,
                    details: props.current.details,
                    garments: props.current.garments,
                    shipping_price: props.current.shipping,
                    setup_price: props.current.setup,
                    total_price: totalPrice,
                    status: props.current.status,
                    store: props.current,
                  });

                  setDone(true);
                }}
              >
                Save Changes
              </button>
            </div>
          )}
          {((props.current.id && props.current.progress.status === "Open") ||
            !props.current.id) &&
            !done && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  className="btn secondary-btn"
                  onClick={async () => {
                    if (props.reseller_code === "ALL") {
                      alert("You must select a reseller to submit an order");
                      return;
                    }
                    if (props.current.customer.customer_id === null) {
                      alert("You must select a customer to submit an order");
                      return;
                    }
                    if (props.current.id)
                      await axios.put("/api/orders/" + props.current.id, {
                        reseller_code: props.reseller_code,
                        customer_id: props.current.customer.customer_id,
                        ship_to_id: props.current.address.id,
                        details: props.current.details,
                        garments: props.current.garments,
                        shipping_price: props.current.shipping,
                        setup_price: props.current.setup,
                        total_price: totalPrice,
                        status: "Open",
                        store: { ...props.current, status: "Open" },
                      });
                    else
                      await axios.post("/api/orders", {
                        reseller_code: props.reseller_code,
                        customer_id: props.current.customer.customer_id,
                        ship_to_id: props.current.address.id,
                        details: props.current.details,
                        garments: props.current.garments,
                        shipping_price: props.current.shipping,
                        setup_price: props.current.setup,
                        total_price: totalPrice,
                        status: "Open",
                        store: { ...props.current, status: "Open" },
                      });
                    setDone(true);
                  }}
                >
                  Save For Later
                </button>
                <button
                  className="btn action-btn"
                  onClick={async () => {
                    if (props.reseller_code === "ALL") {
                      alert("You must select a reseller to submit an order");
                      return;
                    }
                    if (props.current.customer.customer_id === null) {
                      alert("You must select a customer to submit an order");
                      return;
                    }
                    try {
                      if (props.current.id) {
                        await axios.put("/api/orders/" + props.current.id, {
                          reseller_code: props.reseller_code,
                          customer_id: props.current.customer.customer_id,
                          ship_to_id: props.current.address.id,
                          details: props.current.details,
                          garments: props.current.garments,
                          shipping_price: props.current.shipping,
                          setup_price: props.current.setup,
                          total_price: totalPrice,
                          status: "Submitted",
                          store: { ...props.current, status: "Submitted" },
                        });
                      } else
                        await axios.post("/api/orders", {
                          reseller_code: props.reseller_code,
                          customer_id: props.current.customer.customer_id,
                          ship_to_id: props.current.address.id,
                          details: props.current.details,
                          garments: props.current.garments,
                          shipping_price: props.current.shipping,
                          setup_price: props.current.setup,
                          total_price: totalPrice,
                          status: "Submitted",
                          store: { ...props.current, status: "Submitted" },
                        });
                      setDone(true);
                    } catch (e) {
                      alert(JSON.stringify(e));
                    }
                  }}
                >
                  Submit Order
                </button>
              </div>
            )}
          {done && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                className="btn secondary-btn"
                onClick={() => {
                  props.resetCurrent();
                  setTimeout(() => (window.location.href = "/order"), 500);
                }}
              >
                Start A New Order
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    reseller_code: state.reseller.current.code,
  };
};
export default connect(mapStateToProps, {
  setActiveGarment,
  resetCurrent,
  removeGarment,
  setShipping,
  setSetup,
})(Summary);
