import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import { useHistory, Prompt } from "react-router-dom";
import { setCustomer, getCustomers, getDesigns } from "../../actions";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const SetupCustomer = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: props.current?.customer });
  const history = useHistory();

  const [tableData, setTableData] = useState([]);

  const onRowSelect = (id) => {
    let dataCopy = [...tableData];
    dataCopy = dataCopy.map((d, i) => {
      if (id !== i) return { ...d, selected: false };
      return d;
    });
    dataCopy[id].selected = !dataCopy[id].selected;
    props.setCustomer(dataCopy[id].selected ? props.customers[id] : {});
    reset(dataCopy[id].selected ? props.customers[id] : {});
    setTableData(dataCopy);
  };

  useEffect(() => {
    props.getCustomers();
    props.getDesigns();
    reset({});
    props.setCustomer({});
  }, []);
  useEffect(() => {
    const data = props.customers.map((c) => {
      return {
        name: c.customer_id,
        value: c.company,
        full_data: c,
        selected: false,
      };
    });
    setTableData(data);
  }, [props.customers]);

  const onSubmit = async (data) => {
    const found = tableData.find((td) => td.selected);
    if (found) {
      if (!props.reseller?.code || props.reseller.code === "ALL") {
        alert("Please select a valid reseller");
        return;
      }
      const res = await axios.patch("/api/customers/" + found.name, {
        ...data,
        address_id: found.full_data.address_id,
        salesperson_id: props.current.customer.salesperson_id,
        reseller_code: props.reseller.code,
      });
      if (res.data.error) {
        toast.dark(res.data.error);
        props.getCustomers();
      }
      props.setCustomer(res.data.customer);
    } else {
      const res = await axios.post("/api/customers", {
        ...data,
        salesperson_id: props.current.customer.salesperson_id,
        reseller_code: props.reseller.code,
      });
      if (res.data.error) {
        toast.dark(res.data.error);
        return;
      }
      props.setCustomer(res.data.customer);
      props.getCustomers();
    }
    toast.dark("Successfully updated customer");
    setCustomer({});
    reset({});
    props.getCustomers();
    setTableData(tableData.map((td) => ({ ...td, selected: null })));
  };

  return (
    <div className="orders">
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => history.goBack()}
          className="btn secondary-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Back
        </button>
        <div>Manage Customers</div>
        <button
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
          className="btn action-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          {tableData.filter((td) => td.selected).length > 0 ? "Save" : "Create"}
        </button>
      </div>
      <div className="content">
        <div className="left">
          <h2>Customer Info</h2>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <input type="hidden" name="id" {...register("id")} />
            <input
              type="text"
              name="name"
              placeholder="Customer name"
              autoComplete="off"
              {...register("company")}
            />
            <select {...register("status")} style={{ width: "40%" }}>
              <option value="Active" selected>
                Active
              </option>
              <option value="Inactive">Inactive</option>
            </select>

            <input
              type="text"
              name="contact_1"
              placeholder="Contact 1"
              {...register("contact_1")}
            />
            <input
              type="text"
              name="contact_2"
              placeholder="Contact 2"
              {...register("contact_2")}
            />
            <input
              type="text"
              name="address_1"
              placeholder="Address 1"
              {...register("address_1")}
            />
            <input
              type="text"
              name="address_2"
              placeholder="Address 2"
              {...register("address_2")}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              {...register("phone")}
            />
            <input
              type="text"
              name="email"
              placeholder="E-mail"
              {...register("email")}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              {...register("city")}
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              {...register("state")}
            />
            <input
              type="text"
              name="zip"
              placeholder="ZIP"
              {...register("zip")}
            />
            {/* <button className="btn action-btn" type="submit">
              Add/Next
            </button> */}
          </form>
        </div>
        <div className="right">
          <h2>Select from existing</h2>

          <QueryTable data={tableData} onRowSelect={onRowSelect} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    customers: state.customers.list,
    reseller: state.reseller.current,
  };
};

export default connect(mapStateToProps, {
  setCustomer,
  getCustomers,
  getDesigns,
})(SetupCustomer);
