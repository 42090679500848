import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  useHistory,
  Redirect,
} from "react-router-dom";
import configureStore from "./store/configureStore";
import Sidebar from "./components/Sidebar/Sidebar";
import Home from "./screens/Home/Home";

import "./App.css";
import { PersistGate } from "redux-persist/integration/react";
import Designs_Placements from "./screens/Orders/Designs_Placements";
import Select_Customer from "./screens/Orders/Select_Customer";
import Select_Order from "./screens/Orders/Select_Order";
import Select_Shipping from "./screens/Orders/Select_Shipping";
import Select_Designs from "./screens/Orders/Select_Designs";
import Select_Garments from "./screens/Orders/Select_Garments";
import Manual_Garments from "./screens/Orders/Manual_Garments";
import Select_Sizes from "./screens/Orders/Select_Sizes";
import OrderSteps from "./components/OrderSteps/OrderSteps";
import Designs from "./screens/Designs/Designs";
import Design from "./screens/Designs/Design";

import SubmittedOrders from "./screens/Reports/SubmittedOrders";
import OpenOrders from "./screens/Reports/OpenOrders";

import Summary from "./screens/Orders/Summary";
import Reports from "./screens/Reports/Reports";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Proof from "./screens/Reports/Proof";
import CheckInOut from "./screens/Reports/CheckInOut";
import SetupReseller from "./screens/Setup/SetupReseller";
import SetupCustomer from "./screens/Setup/SetupCustomer";

export default function App() {
  const { store, persistor } = configureStore();
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  return (
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <Router>
          <div className="container">
            <Sidebar />

            <div className="main">
              <Route
                path="/order"
                render={() => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <OrderSteps currentStep={currentStep} />
                    </div>
                  );
                }}
              />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/order"
                  render={() => (
                    <Select_Customer setCurrentStep={setCurrentStep} />
                  )}
                />
                <Route
                  exact
                  path="/order/new"
                  render={() => (
                    <Select_Order setCurrentStep={setCurrentStep} />
                  )}
                />
                <Route
                  exact
                  path="/order/details"
                  render={() => (
                    <Select_Shipping setCurrentStep={setCurrentStep} />
                  )}
                />
                <Route
                  exact
                  path="/order/designs"
                  render={() => (
                    <Select_Designs setCurrentStep={setCurrentStep} />
                  )}
                />
                <Route
                  exact
                  path="/order/distributor"
                  render={() => (
                    <Select_Garments setCurrentStep={setCurrentStep} />
                  )}
                />
                <Route
                  exact
                  path="/order/distributor/manual"
                  render={() => (
                    <Manual_Garments setCurrentStep={setCurrentStep} />
                  )}
                />
                <Route
                  exact
                  path="/order/quantities"
                  render={() => (
                    <Select_Sizes setCurrentStep={setCurrentStep} />
                  )}
                />
                <Route
                  exact
                  path="/order/placements"
                  render={() => (
                    <Designs_Placements setCurrentStep={setCurrentStep} />
                  )}
                />
                <Route
                  exact
                  path="/order/summary"
                  render={() => <Summary setCurrentStep={setCurrentStep} />}
                />

                <Route exact path="/designs" component={Designs} />
                <Route path="/designs/:id" component={Design} />
                <Route path="/proof/:id" component={Proof} />
                <Route path="/resellers" component={SetupReseller} />
                <Route path="/customers" component={SetupCustomer} />

                <Route exact path="/reports" component={Reports} />
                <Route
                  exact
                  path="/reports/submitted"
                  component={SubmittedOrders}
                />

                <Route exact path="/reports/open" component={OpenOrders} />
                <Route exact path="/reports/check-in" component={CheckInOut} />
                <Route
                  exact
                  path="/reports/check-out"
                  render={() => <CheckInOut check_out={true} />}
                />

                {/* <Home /> */}
              </Switch>
            </div>
          </div>
        </Router>
      </PersistGate>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  );
}
