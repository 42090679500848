import React, { useEffect, useState } from "react";
import "./Orders.css";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import SizeList from "../../components/SizeList/SizeList";
import { useHistory, Prompt } from "react-router-dom";
import { setDetails, setAddress } from "../../actions/ordersActions";
import { connect } from "react-redux";

const Select_Shipping = (props) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: props.current.details });

  const {
    register: register2,
    errors: errors2,
    reset: reset2,
    handleSubmit: handleSubmit2,
  } = useForm({
    defaultValues: props.current.address,
  });

  const onAddressChange = (e) => {
    let t = null;
    if (e.target.value === "reseller") {
      t = props.reseller;
    } else if (e.target.value === "customer") {
      t = props.current.customer;
    }
    const {
      company,
      address_1,
      address_2,
      contact_1,
      contact_2,
      city,
      state,
      zip,
      email,
      phone,
    } = t;

    props.setAddress({
      company,
      address_1,
      address_2,
      contact_1,
      contact_2,
      city,
      state,
      zip,
      email,
      phone,
    });

    reset2(t);
  };
  const onSubmit = (data) => {
    props.setDetails(data);
  };

  const onSubmit2 = (data) => {
    handleSubmit(onSubmit)();
    props.setAddress(data);
  };
  useEffect(() => {
    const {
      company,
      address_1,
      address_2,
      contact_1,
      contact_2,
      city,
      state,
      zip,
      email,
      phone,
    } = props.reseller;

    props.setAddress({
      company,
      address_1,
      address_2,
      contact_1,
      contact_2,
      city,
      state,
      zip,
      email,
      phone,
    });

    reset2(props.reseller);
  }, []);
  return (
    <div className="orders">
      <Prompt
        when={true}
        message={(location, action) => {
          handleSubmit2(onSubmit2)();
          return true;
        }}
      />
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => history.goBack()}
          className="btn secondary-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Back
        </button>
        <div>Create a new order</div>
        <button
          onClick={() => {
            handleSubmit2(onSubmit2)();
            setTimeout(() => history.push("/order/designs"), 500);
          }}
          className="btn action-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Next
        </button>
      </div>
      <div className="content">
        <div className="left single-col">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ flexDirection: "row" }}
          >
            <h2>Order Details</h2>
            <input
              type="text"
              name="job_name"
              placeholder="Job Name"
              {...register("job_name")}
            />
            {/* <input
              type="text"
              name="contact"
              placeholder="Contact"
              {...register("contact")}
            /> */}
            <input
              type="text"
              name="po_number"
              placeholder="PO Number"
              {...register("po_number")}
            />
            <label style={{ marginLeft: "20px" }}>
              Requested Hands-In Date
            </label>
            <input
              type="datetime-local"
              name="requested_date"
              placeholder="Requested Hands-In Date"
              {...register("requested_date")}
            />
            <select
              type="text"
              name="shipping_method"
              placeholder="Shipping Method"
              {...register("shipping_method")}
            >
              <option selected disabled>
                Shipping Method
              </option>
              <option value="Local pickup">Local pickup</option>
              <option value="Common carrier">Common Carrier</option>
              <option value="Courier Delivery Cities">
                Courier Delivery Cities
              </option>
              <option value="Courier Delivery Local">
                Courier Delivery Local
              </option>
            </select>
            <textarea
              type="text"
              name="instructions"
              placeholder="Instructions"
              rows={10}
              {...register("instructions")}
            />
          </form>
        </div>
        <div
          className="right"
          style={{ flex: 2, borderLeft: "1px solid black", textIndent: "15px" }}
        >
          <h2>Ship to</h2>
          <select onChange={onAddressChange}>
            <option value="reseller" selected>
              Reseller's Default Address - {props.reseller.address_1},
              {props.reseller.city}, {props.reseller.state}
            </option>
            <option value="customer">
              Customer's Default Address - {props.customer.address_1},
              {props.customer.city}, {props.customer.state}
            </option>
            <option value="one-time">Ship order to one-time address...</option>
          </select>
          <form
            onSubmit={handleSubmit2(onSubmit2)}
            style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
          >
            <input
              type="text"
              name="company"
              placeholder="Name"
              {...register2("company")}
            />

            <input
              type="text"
              name="contact_1"
              placeholder="Contact 1"
              {...register2("contact_1")}
            />
            <input
              type="text"
              name="contact_2"
              placeholder="Contact 2"
              {...register2("contact_2")}
            />
            <input
              type="text"
              name="address_1"
              placeholder="Address 1"
              {...register2("address_1")}
            />
            <input
              type="text"
              name="address_2"
              placeholder="Address 2"
              {...register2("address_2")}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              {...register2("phone")}
            />
            <input
              type="text"
              name="email"
              placeholder="E-mail"
              {...register2("email")}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              {...register2("city")}
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              {...register2("state")}
            />
            <input
              type="text"
              name="zip"
              placeholder="ZIP"
              {...register2("zip")}
            />
            {/* <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button className="btn action-btn" type="submit">
                Next
              </button>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    customer: state.orders.current.customer,
    reseller: state.reseller.current,
  };
};
export default connect(mapStateToProps, { setDetails, setAddress })(
  Select_Shipping
);
