import axios from "axios";

export const setResellerAuth = (data) => {
  data
    ? (axios.defaults.headers["x-reseller-code"] = data)
    : delete axios.defaults.headers["x-reseller-code"];
};

export const setCustomerAuth = (data) => {
  data
    ? (axios.defaults.headers["x-customer-id"] = data)
    : delete axios.defaults.headers["x-customer-id"];
};
