import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import { connect } from "react-redux";
import { getDesigns, getCustomers, setCustomer } from "../../actions";
import { submitDesign, updateDesign } from "../../api";
import { useHistory } from "react-router-dom";
const Designs = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    getValues,
  } = useForm();
  const history = useHistory();

  const [preview, setPreview] = useState();
  const [artIdPreview, setArtIdPreview] = useState("");
  const [file, setFile] = useState();
  const [tableData, setTableData] = useState([]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name === "size_code" ||
        name === "screen_code" ||
        name === "color_code" ||
        name === "next_number"
      ) {
        setArtIdPreview(
          (value.reseller_code ?? props.reseller_code) +
            "-" +
            value.next_number.padStart(5, "0") +
            "-" +
            value.screen_code +
            "-" +
            value.size_code +
            value.color_code
        );
      }
      return () => subscription.unsubscribe();
    });
  }, [watch]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    setTableData([]);
    props.getDesigns();
  }, [props.current.customer, props.reseller_code]);

  const onRowSelect = (id) => {
    let dataCopy = [...tableData];
    dataCopy = dataCopy.map((d, i) => {
      if (id !== i) return { ...d, selected: false };
      return d;
    });
    dataCopy[id].selected = !dataCopy[id].selected;

    reset(dataCopy[id].selected ? dataCopy[id].full_data : {});
    setTableData(dataCopy);

    if (dataCopy[id].selected) {
      if (dataCopy[id].full_data.status === "Approved")
        history.push("/designs/" + dataCopy[id].full_data.id);
      else setArtIdPreview(dataCopy[id].art_id);
    }
  };

  useEffect(() => {
    if (props.designs.length === 0) return;

    const d = props.designs
      .filter((a) => a.status !== "Archived")
      .sort((a, b) => b.status.localeCompare(a.status))
      .map((a) => {
        return {
          name: a.art_id ?? a.id,
          thumbnail: "/api/designs/" + a.id + ".png",
          value: (a.art_id ?? a.id) + " [" + a.status + "]",
          selected: false,
          full_data: a,
        };
      });

    setTableData(d);
  }, [props.designs]);
  const onSubmit = async (data) => {
    console.log(data);
    let base64 = null;
    if (file) base64 = await getBase64(file);

    const found = tableData.find((td) => td.selected);
    if (!found) {
      await submitDesign({
        ...data,
        art_id: artIdPreview,
        base64,
        reseller_code: props.reseller_code,
      });
    } else
      await updateDesign(found.full_data.id, {
        ...data,
        art_id: artIdPreview,
        base64,
        reseller_code: props.reseller_code,
      });
    window.location.reload();
  };

  return (
    <div className="home" style={{ marginTop: 0 }}>
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <div>Add or edit designs</div>
        <button
          disabled={!tableData.find((td) => td.selected)}
          onClick={() => {
            history.push(
              "/designs/" + tableData.find((td) => td.selected).full_data.id
            );
          }}
          className="btn action-btn"
          style={{
            padding: 0,
            margin: 0,
            width: "10rem",
            height: "3rem",
            paddingBlock: 0,
          }}
        >
          {!tableData.find((td) => td.selected)
            ? "Please select a design"
            : "View Design"}
        </button>
      </div>

      <div className="content">
        <div className="left">
          <h2>New Design</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <select
                {...register("customer_id")}
                style={{ width: "40%" }}
                value={props.current.customer?.customer_id}
                onChange={(e) =>
                  props.setCustomer(
                    props.customers.find((c) => c.customer_id == e.target.value)
                  )
                }
              >
                <option disabled selected>
                  Please select one
                </option>
                {props.customers.map((c) => {
                  return <option value={c.customer_id}>{c.company}</option>;
                })}
              </select>
              <input
                type="text"
                name="name"
                placeholder="Design Name"
                {...register("name")}
              />
            </div>

            <div>
              <input
                type="text"
                name="width"
                placeholder="Width"
                {...register("width")}
                style={{ width: "120px" }}
              />
              <input
                type="text"
                name="height"
                placeholder="Height"
                {...register("height")}
                style={{ width: "120px" }}
              />
            </div>

            <div>
              <input
                type="text"
                name="ink_color_1"
                placeholder="Ink/Thread Color 1"
                {...register("ink_color_1")}
              />
              <input
                type="text"
                name="ink_color_2"
                placeholder="Ink/Thread Color 2"
                {...register("ink_color_2")}
              />
              <input
                type="text"
                name="ink_color_3"
                placeholder="Ink/Thread Color 3"
                {...register("ink_color_3")}
              />
              <input
                type="text"
                name="ink_color_4"
                placeholder="Ink/Thread Color 4"
                {...register("ink_color_4")}
              />
              <input
                type="text"
                name="ink_color_5"
                placeholder="Ink/Thread Color 5"
                {...register("ink_color_5")}
              />
              <input
                type="text"
                name="ink_color_6"
                placeholder="Ink/Thread Color 6"
                {...register("ink_color_6")}
              />
              <input
                type="text"
                name="ink_color_7"
                placeholder="Ink/Thread Color 7"
                {...register("ink_color_7")}
              />{" "}
              <input
                type="text"
                name="ink_color_8"
                placeholder="Ink/Thread Color 8"
                {...register("ink_color_8")}
              />
            </div>
            <div>
              <input
                type="text"
                name="next_number"
                placeholder="Next Number"
                {...register("next_number")}
                style={{ width: "120px" }}
              />
              <input
                type="text"
                name="screen_code"
                placeholder="Screen Code"
                {...register("screen_code")}
                style={{ width: "120px" }}
              />
              <input
                type="text"
                name="size_code"
                placeholder="Size Code"
                {...register("size_code")}
                style={{ width: "120px" }}
              />
              <input
                type="text"
                name="color_code"
                placeholder="Color Code"
                {...register("color_code")}
                style={{ width: "120px" }}
              />
            </div>
            <div>
              <input
                type="text"
                name="name"
                placeholder="Art ID"
                {...register("art_id")}
                value={artIdPreview}
                disabled
              />
            </div>
            <input
              type="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
                setPreview(URL.createObjectURL(e.target.files[0]));
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img
                className="box"
                style={{
                  width: "128px",
                  height: "128px",
                  display: "inline-flex",
                }}
                src={preview}
              />
              <button
                className="btn action-btn"
                type="submit"
                style={{ marginRight: "12%" }}
              >
                {!tableData.find((td) => td.selected) ? "Add" : "Save"}
              </button>
            </div>
          </form>
        </div>
        <div className="right">
          <h2>Select from existing</h2>

          <QueryTable data={tableData} onRowSelect={onRowSelect} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    customers: state.customers.list,
    designs: state.designs.list,
    reseller_code: state.reseller.current.code,
  };
};

export default connect(mapStateToProps, {
  getCustomers,
  getDesigns,
  setCustomer,
})(Designs);
