import {
  GET_ORDERS,
  SET_CUSTOMER,
  SET_ADDRESS,
  SET_DETAILS,
  SET_GARMENTS,
  UPDATE_GARMENT,
  ADD_GARMENT,
  SET_DESIGNS,
  SET_SIZES,
  SET_ACTIVE,
  ADD_PLACEMENT,
  RESET_CURRENT,
  REMOVE_GARMENT,
  RESET_SIZING,
  RESET_PLACEMENTS,
  SET_SHIPPING,
  SET_SETUP,
  LOAD_ORDER,
  RESET_CUSTOMER,
} from "../actions/types";

const initialState = {
  list: [],
  current: {
    id: null, //null = create
    active_garment: null,
    customer: {},
    address: {},
    details: {},
    designs: [],
    garments: [],
    setup: 0,
    shipping: 0,
  },
};

export default function (state = initialState, action) {
  // console.log(action);
  let garments = [];
  switch (action.type) {
    case LOAD_ORDER: {
      return { ...state, current: action.payload };
    }
    case GET_ORDERS:
      return {
        ...state,
        list: action.payload,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        current: { ...state.current, customer: action.payload },
      };
    case SET_ADDRESS:
      return {
        ...state,
        current: { ...state.current, address: action.payload },
      };
    case SET_DETAILS:
      return {
        ...state,
        current: { ...state.current, details: action.payload },
      };

    case ADD_GARMENT:
      console.log(action.payload);
      return {
        ...state,
        current: {
          ...state.current,
          garments: state.current.garments.concat({
            ...action.payload,
          }),
          active_garment: state.current.garments.length,
        },
      };
    case UPDATE_GARMENT:
      garments = [...state.current.garments];
      garments[action.payload.id] = {
        ...garments[action.payload.id],
        ...action.payload.garment,
      };
      return {
        ...state,
        current: { ...state.current, garments },
      };
    case SET_DESIGNS:
      return {
        ...state,
        current: {
          ...state.current,
          designs: [...action.payload],
        },
      };
    case SET_SIZES:
      garments = [...state.current.garments];
      garments[state.current.active_garment].sizes = action.payload.sizes;
      return { ...state, current: { ...state.current, garments } };
    case ADD_PLACEMENT:
      garments = [...state.current.garments];
      garments[action.payload.id] = {
        ...garments[action.payload.id],
        placements: action.payload.placements,
      };
      return { ...state, current: { ...state.current, garments } };
    case SET_ACTIVE:
      return {
        ...state,
        current: { ...state.current, active_garment: action.payload },
      };
    case RESET_CURRENT:
      return {
        ...state,
        current: {
          ...state.current,
          id: null,
          active_garment: null,
          address: {},
          details: {},
          designs: [],
          garments: [],
          setup: 0,
          shipping: 0,
        },
      };
    case RESET_CUSTOMER:
      return { ...state, current: { ...state.current, customer: {} } };
    case REMOVE_GARMENT:
      return {
        ...state,
        current: {
          ...state.current,
          garments: state.current.garments.filter(
            (g, i) => i !== action.payload
          ),
        },
      };
    case RESET_SIZING:
      return {
        ...state,
        current: {
          ...state.current,
          garments: state.current.garments.map((g, i) => {
            if (i === action.payload)
              return {
                ...g,
                sizes: g.sizes?.map((s) => ({
                  size: s.size ?? s,
                  qty: null,
                  price: null,
                })),
              };

            return g;
          }),
        },
      };
    case RESET_PLACEMENTS:
      return {
        ...state,
        current: {
          ...state.current,
          garments: state.current.garments.map((g, i) => {
            if (i === action.payload) return { ...g, placements: null };

            return g;
          }),
        },
      };
    case SET_SHIPPING:
      return {
        ...state,
        current: {
          ...state.current,
          shipping: action.payload,
        },
      };
    case SET_SETUP:
      return {
        ...state,
        current: {
          ...state.current,
          setup: action.payload,
        },
      };
    default:
      return state;
  }
}
