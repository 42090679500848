import { GET_RESELLERS, GET_ERRORS, SET_RESELLER } from "./types";
import { getResellers as getResellersAPI } from "../api";
export const getResellers = () => async (dispatch) => {
  try {
    const res = await getResellersAPI();
    dispatch({ type: GET_RESELLERS, payload: res.data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
export const setReseller = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_RESELLER, payload: data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
