import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SubmittedOrders.css";
import { getOrders, loadOrder } from "../../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import FilterCheckbox, {
  Status,
} from "../../components/FilterCheckbox/FilterCheckbox";
const fileDownload = require("js-file-download");

const OpenOrders = (props) => {
  const [localOrders, setLocalOrders] = useState([]);
  const [selected, setSelected] = useState(null);

  const [filters, setFilters] = useState({});
  let history = useHistory();

  const [sortConfig, setSortConfig] = useState({
    name: "",
    company: "",
  });

  useEffect(() => {
    props.getOrders();
  }, []);

  const applyFilters = (orders) => {
    return orders.map((o) => {
      let visible = o.status === "Open";

      for (const [key, value] of Object.entries(filters)) {
        if (value === Status.IGNORE) continue;

        if (value === Status.INCLUDE && !o.progress[key]) {
          visible = false;
          break;
        } else if (value === Status.EXCLUDE && o.progress[key]) {
          visible = false;
          break;
        }
      }

      return { ...o, visible };
    });
  };

  useEffect(() => {
    setLocalOrders(applyFilters([...localOrders]));
  }, [filters]);

  const onExportReport = async () => {
    const res = await axios.get("/api/orders/" + selected + "/report", {
      responseType: "blob",
    });

    if (!res.data.error) {
      const order = props.orders.find((f) => f.id === selected);
      fileDownload(
        res.data,
        `[OPEN] 10${order.id.toString().padStart(4, "0")} - ${
          order.job_name
        }.html`
      );
    }
  };
  const onSortChange = (type) => {
    const currentSort = sortConfig[type];
    let nextSort;

    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "down";
    else if (currentSort === "") nextSort = "down";

    for (var key in sortConfig) {
      if (sortConfig.hasOwnProperty(key)) {
        sortConfig[key] = key === type ? nextSort : "";
      }
    }

    setSortConfig({ ...sortConfig });
  };

  useEffect(() => {
    if (props.orders?.length > 0) {
      const orders = props.orders
        .filter((o) => o.status === "Open")
        .map((o) => {
          let checkedIn = 0;
          let checkedOut = 0;
          let total = 0;

          o.garments.map((g) => {
            g.sizes
              ?.filter((s) => s.qty && s.qty > 0)
              .map((s) => {
                if (s.checked_in) checkedIn += s.checked_in;
                if (s.checked_out) checkedOut += s.checked_out;
                total += s.qty;
              });
          });

          return {
            id: o.id,
            name: o.job_name,
            company: o.customer?.company,
            progress: o.progress,
            requested_date: o.requested_date,
            order_date: o.created_at,
            status: o.status,
            visible: o.status === "Open",
          };
        });

      setLocalOrders(applyFilters(orders));
    }
  }, [props.orders]);

  const toggleProgress = (id, name) => {
    let newOrders = [...localOrders];

    newOrders = newOrders.map((no) => {
      if (no.id === id)
        return {
          ...no,
          progress: {
            ...no.progress,
            [name]: no.progress[name] ? null : new Date(),
          },
          edited: true,
        };

      return no;
    });

    setLocalOrders(newOrders);
  };

  const onUpdateProgress = async () => {
    try {
      const editedOrders = localOrders.filter((o) => o.edited);

      const res = await axios.put("/api/orders/batch/progress", {
        orders: editedOrders,
      });
      if (res.error) throw res.error;
      toast.dark(
        "Successfully updated progress for " + editedOrders.length + " orders!"
      );

      props.getOrders();
    } catch (e) {
      toast.dark(e.message);
    }
  };

  const onCheckboxClick = (type) => {
    setFilters({
      ...filters,
      [type]:
        !filters[type] || filters[type] === Status.IGNORE
          ? Status.INCLUDE
          : filters[type] === Status.INCLUDE
          ? Status.EXCLUDE
          : Status.IGNORE,
    });
  };

  return (
    <div className="home" style={{ marginTop: 0 }}>
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: "0",
          marginBlockStart: "2rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <button
            className={selected ? "btn danger-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 5, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={async () => {
              if (
                window.confirm(
                  localOrders.find((f) => f.id === selected).status ===
                    "Canceled"
                    ? "Are you sure you want to restore this order?"
                    : "Are you sure you want to cancel this order?"
                )
              ) {
                const order = props.orders.find((f) => f.id === selected);
                if (!order) return;

                await axios.put("/api/orders/" + order.id + "/status", {
                  status: order.status === "Canceled" ? "Open" : "Canceled",
                });

                props.getOrders();
              }
            }}
          >
            {!localOrders.find((f) => f.id === selected)
              ? "Please select one"
              : localOrders.find((f) => f.id === selected).status === "Canceled"
              ? "Restore order"
              : "Cancel order"}
          </button>
        </div>
        <p style={{ marginBlockEnd: "0", marginBlockStart: "0" }}>
          Current Open Orders
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "50%",
          }}
        >
          <button
            className={selected ? "btn action-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={() => {
              props.loadOrder(selected);
              setTimeout(
                () =>
                  (window.location.href =
                    props.reseller_code === "ALL"
                      ? "/order/summary"
                      : "/proof/" + selected),
                500
              );
            }}
          >
            {localOrders.find((f) => f.id === selected)
              ? "View proof"
              : "Please select one"}
          </button>
          <button
            className={selected ? "btn action-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={onExportReport}
          >
            {localOrders.find((f) => f.id === selected)
              ? "Export report"
              : "Please select one"}
          </button>
          <button
            className={selected ? "btn action-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={async () => {
              const res = await axios.get(
                "/api/orders/" + selected + "/export"
              );
              fileDownload(res.data, "open_order_" + selected + ".csv");
            }}
          >
            {localOrders.find((f) => f.id === selected)
              ? "Export order"
              : "Please select one"}
          </button>
          <button
            className="btn action-btn"
            onClick={onUpdateProgress}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={localOrders.filter((f) => f.edited).length === 0}
          >
            Update progress
          </button>
        </div>
      </div>

      <div className="content">
        <div className="query-table reports-table">
          <table>
            <thead>
              <tr
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#ddd",
                }}
              >
                <th
                  style={{ cursor: "pointer", userSelect: "none" }}
                  onClick={() => {
                    onSortChange("name");

                    setLocalOrders(
                      [...localOrders].sort((a, b) =>
                        sortConfig.name === ""
                          ? a
                          : sortConfig.name === "up"
                          ? b.name?.localeCompare(a?.name)
                          : a.name?.localeCompare(b?.name)
                      )
                    );
                  }}
                >
                  Order Name&nbsp;
                  <i className={`fa fa-sort`} />
                </th>

                <th>
                  <div className="checkbox-container">
                    Quoted
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("quoted")}
                      status={filters["quoted"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Art Sent
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("proof_sent")}
                      status={filters["proof_sent"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Proof Approved
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("proof_approved")}
                      status={filters["proof_approved"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Invoiced
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("invoiced")}
                      status={filters["invoiced"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Paid
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("paid")}
                      status={filters["paid"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Garments Ordered
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("garments_ordered")}
                      status={filters["garments_ordered"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Digitizing Ordered
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("digitizing_ordered")}
                      status={filters["digitizing_ordered"]}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {[...localOrders]
                ?.filter((o) => o.visible)
                .map((o, i) => {
                  return (
                    <tr
                      className={
                        o.edited
                          ? "row-pending"
                          : selected === o.id
                          ? "row-selected"
                          : ""
                      }
                    >
                      <td
                        onClick={() =>
                          selected === o.id
                            ? setSelected(null)
                            : setSelected(o.id)
                        }
                      >
                        {o.company}
                      </td>

                      <td
                        className={o.progress.quoted ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "quoted")}
                      ></td>
                      <td
                        className={o.progress.proof_sent ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "proof_sent")}
                      ></td>
                      <td
                        className={o.progress.proof_approved ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "proof_approved")}
                      ></td>
                      <td
                        className={o.progress.invoiced ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "invoiced")}
                      ></td>
                      <td
                        className={o.progress.paid ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "paid")}
                      ></td>

                      <td
                        className={
                          o.progress.garments_ordered ? "completed" : ""
                        }
                        onClick={() => toggleProgress(o.id, "garments_ordered")}
                      ></td>
                      <td
                        className={
                          o.progress.digitizing_ordered ? "completed" : ""
                        }
                        onClick={() =>
                          toggleProgress(o.id, "digitizing_ordered")
                        }
                      ></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders.list,
    reseller_code: state.reseller.current.code,
  };
};
export default connect(mapStateToProps, { getOrders, loadOrder })(OpenOrders);
