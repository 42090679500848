import React, { useEffect, useState } from "react";
import "./SubmittedOrders.css";
import { getOrders, loadOrder } from "../../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { approveDesign } from "../../api";

import axios from "axios";
import Summary from "../Orders/Summary";
const Proof = (props) => {
  const [order, setOrder] = useState(null);
  const [activeDesign, setActiveDesign] = useState(0);

  const [activeGarment, setActiveGarment] = useState(0);
  const [activePlacement, setActivePlacement] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [pendingDesigns, setPendingDesigns] = useState([]);
  const [page, setPage] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  const populateOrder = async () => {
    const res = await axios.get("/api/orders/" + id);
    setOrder(res.data);
  };
  useEffect(() => {
    populateOrder();
  }, []);

  useEffect(() => {
    if (!order || !order.garments || !order.progress) return;

    if (order.designs.filter((d) => d.status === "Pending").length > 0) {
      setPendingDesigns(order.designs.filter((d) => d.status === "Pending"));
      setPage("designs");
    } else if (order.progress.proof_approved || order.status === "Approved") {
      props.loadOrder(id);
      setPage("menu");
    } else setPage("garments");
  }, [order]);
  useEffect(() => {
    if (!order || !order.garments || !order.progress) return;

    if (pendingDesigns.length === activeDesign && page !== "garments")
      setPage("garments");
    else if (order.garments.length === activeGarment) {
      props.loadOrder(id);
      setPage("menu");
    } else if (
      !order.garments.placements ||
      order.garments[activeGarment]?.placements.length === activePlacement
    ) {
      setActiveGarment(activeGarment + 1);
      setActivePlacement(0);
    }
  }, [activeDesign, activeGarment, activePlacement]);

  const getDesignName = (id) => {
    const design = order.designs.find((d) => d.id === id);
    if (design && design.art_id) return design.art_id;
    else if (design) return "Design #" + id;

    return "Unknown design";
  };

  const getDesign = (id) => {
    const design = order.designs.find((d) => d.id === id);

    if (design && design.full_data) return design.full_data;
    else if (design) return design;

    return null;
  };

  return (
    <div>
      {page === "menu" && (
        <Summary
          approve_proof={true}
          approve_data={{
            ...order,
            status: "Approved",
            progress: { ...order.progress, proof_approved: new Date() },
          }}
        />
      )}
      {page === "designs" && pendingDesigns[activeDesign] && (
        <div style={{ textAlign: "center" }}>
          <p>
            {pendingDesigns[activeDesign]?.art_id} ({activeDesign + 1} /{" "}
            {pendingDesigns.length})
          </p>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <h2>Details</h2>
              <div>
                <h3>Status: </h3>
                {pendingDesigns[activeDesign].status}
              </div>
              <div>
                <h3>Name: </h3>
                {pendingDesigns[activeDesign].name}
              </div>
              <div>
                <h3>Width: </h3>
                {pendingDesigns[activeDesign].width}
              </div>
              <div>
                <h3>Height: </h3>
                {pendingDesigns[activeDesign].height}
              </div>
              <div>
                <h3>Ink/Thread Colors:</h3>
                <p>{pendingDesigns[activeDesign].ink_color_1}</p>
                <p>{pendingDesigns[activeDesign].ink_color_2}</p>
                <p>{pendingDesigns[activeDesign].ink_color_3}</p>
                <p>{pendingDesigns[activeDesign].ink_color_4}</p>
                <p>{pendingDesigns[activeDesign].ink_color_5}</p>
                <p>{pendingDesigns[activeDesign].ink_color_6}</p>
                <p>{pendingDesigns[activeDesign].ink_color_7}</p>
                <p>{pendingDesigns[activeDesign].ink_color_8}</p>
              </div>
              <button
                style={{ width: "100%" }}
                onClick={async () => {
                  await approveDesign(pendingDesigns[activeDesign].id);
                  setActiveDesign(activeDesign + 1);
                }}
              >
                Approve design
              </button>
            </div>
            <img
              src={"/api/designs/" + pendingDesigns[activeDesign]?.id + ".png"}
              alt="design"
              style={{
                backgroundColor: "#bbb",
                width: "350px",
                height: "auto",
              }}
            />
          </div>
        </div>
      )}
      {page === "garments" && order.garments[activeGarment]?.placements && (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <p>
            {order?.garments && order.garments[activeGarment]?.title} (
            {activeGarment + 1} / {order?.garments?.length})
          </p>
          <img
            src={
              order?.garments[activeGarment]?.placements[activePlacement]
                ?.base64 ??
              order?.garments[activeGarment]?.placements[activePlacement]?.image
            }
            style={{ maxHeight: "720px", maxWidth: "900px" }}
            alt="placement"
          />

          <div>
            {order?.garments[activeGarment]?.placements[activePlacement]
              ?.added && (
              <div>
                <p>
                  Art ID:{" "}
                  <strong>
                    {getDesignName(
                      order?.garments[activeGarment]?.placements[
                        activePlacement
                      ]?.id
                    )}
                  </strong>
                </p>{" "}
                <p>
                  Name:{" "}
                  <strong>
                    {
                      order?.garments[activeGarment]?.placements[
                        activePlacement
                      ]?.name
                    }
                  </strong>
                </p>{" "}
                <p>
                  Decoration Method:{" "}
                  <strong>
                    {
                      order?.garments[activeGarment]?.placements[
                        activePlacement
                      ]?.decoration_method
                    }
                  </strong>
                </p>{" "}
                <p>
                  Dimensions:{" "}
                  <strong>
                    {
                      getDesign(
                        order?.garments[activeGarment]?.placements[
                          activePlacement
                        ]?.id
                      )?.width
                    }
                    {'" '}x{" "}
                    {
                      getDesign(
                        order?.garments[activeGarment]?.placements[
                          activePlacement
                        ].id
                      )?.height
                    }
                    {'" @ '}(
                    {
                      order?.garments[activeGarment]?.placements[
                        activePlacement
                      ]?.x
                    }
                    ,
                    {
                      order?.garments[activeGarment]?.placements[
                        activePlacement
                      ]?.y
                    }
                    )
                  </strong>
                </p>
              </div>
            )}
            {!order?.garments[activeGarment]?.placements[activePlacement]
              ?.added && (
              <div className="alert alert-danger">
                No placement has been added to this side of the garment
              </div>
            )}
          </div>
          <button onClick={() => setActivePlacement(activePlacement + 1)}>
            {!order?.garments[activeGarment]?.placements[activePlacement]?.added
              ? "Acknowledge"
              : "Approve"}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps, { loadOrder, approveDesign })(Proof);
