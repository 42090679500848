import { GET_DESIGNS, GET_ERRORS } from "./types";
import { getDesigns as getDesignsAPI } from "../api";
export const getDesigns  = () => async (dispatch) => {
  try {
    const res = await getDesignsAPI();
    dispatch({ type: GET_DESIGNS, payload: res.data });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};