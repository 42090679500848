import React from "react";

export const Status = {
  INCLUDE: "include",
  EXCLUDE: "exclude",
  IGNORE: "ignore",
};

const FilterCheckbox = (props) => {
  const { status, ...otherProps } = props;

  return (
    <div
      className={`filter-checkbox filter-${status ?? "ignore"}`}
      {...otherProps}
    />
  );
};

export default FilterCheckbox;
