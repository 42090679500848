import React, { useState, useEffect } from "react";

import "./QueryTable.css";

const QueryTable = (props) => {
  const { title, data, count, onRowSelect } = props;
  // const onRowSelect = (id) => {
  //   const dataCopy = [...data];
  //   dataCopy[id].selected = !dataCopy[id].selected;
  //   setData(dataCopy);
  // };
  // const [data, setData] = useState([
  //   { id: 0, name: "Record Number 1", value: "25642", selected: false },
  //   { id: 1, name: "Record Number 2", value: "25642", selected: false },
  //   { id: 2, name: "Record Number 3", value: "25642", selected: false },
  //   { id: 3, name: "Record Number 4", value: "25642", selected: false },
  //   { id: 4, name: "Record Number 5", value: "25642", selected: false },
  //   { id: 5, name: "Record Number 6", value: "25642", selected: false },
  //   { id: 6, name: "Record Number 7", value: "25642", selected: false },
  // ]);
  return (
    <div className="query-table">
      <table>
        <caption>{title}</caption>
        <tr>
          <th></th>
          <th></th>
        </tr>
        {data.slice(0, count).map((d, i) => {
          return (
            <tr
              onClick={() => onRowSelect(i)}
              className={d.selected ? "selected" : ""}
              style={{textAlign: "center"}}
            >
              <td>{d.thumbnail ? <img className="img-thumbnail" src={d.thumbnail} /> : d.name}</td>
              <td>{d.value}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default QueryTable;
