import React, { useEffect, useState } from "react";
import "./Orders.css";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getDesigns, setDesigns } from "../../actions";
const Select_Designs = (props) => {
  const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [selectedDesigns, setSelectedDesigns] = useState([]);

  const [previews, setPreviews] = useState([]);

  const populateDesigns = () => {
    const data = props.designs
      // .filter((d) => d.status === "Approved")
      .map((d) => {
        return {
          name: d.id,
          thumbnail: "/api/designs/" + d.id + ".png",
          value: d.art_id ?? "Design #" + d.id,
          full_data: d,
          selected: false,
        };
      });
    setTableData(data);
  };

  useEffect(() => {
    props.getDesigns();
    props.setCurrentStep(1);
    // populateDesigns();
  }, []);

  useEffect(() => {
    props.getDesigns();
  }, [props.current.customer, props.reseller]);

  useEffect(() => {
    populateDesigns();
  }, [props.designs]);

  const onRowSelect = (id) => {
    const dataCopy = [...tableData];
    dataCopy[id].selected = !dataCopy[id].selected;

    if (dataCopy[id].selected) {
      setPreviews([...previews, dataCopy[id].thumbnail]);
    } else {
      setPreviews(previews.filter((p) => p !== dataCopy[id].thumbnail));
    }

    setTableData(dataCopy);
  };

  const onAddSelected = () => {
    setSelectedDesigns([
      ...selectedDesigns,
      ...tableData
        .filter((td) => td.selected)
        .map((td) => ({ ...td, selected: false })),
    ]);
    setTableData(tableData.filter((td) => !td.selected));
    setPreviews([]);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    if (selectedDesigns.length === 0) return;

    const designs = selectedDesigns.map((sd) => ({
      id: sd.name,
      url: sd.thumbnail,
      full_data: sd.full_data,
    }));

    props.setDesigns(designs);

    setTimeout(() => history.push("/order/distributor"), 500);
  };
  // useEffect(() => {}, [props.current.designs]);
  return (
    <div className="orders">
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => history.goBack()}
          className="btn secondary-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Back
        </button>
        <div>Create a new order</div>
        <button
          onClick={onSubmit}
          className="btn action-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Next
        </button>
      </div>
      <div className="content">
        <div className="left">
          <h2>Preview</h2>
          <div className="list" style={{ maxHeight: "50vh" }}>
            {previews.map((p) => {
              return <img src={p} className="preview-icon" />;
            })}
          </div>
        </div>
        <div className="center">
          <h2>Designs List</h2>
          <QueryTable data={tableData} onRowSelect={onRowSelect} />
        </div>
        <div className="right">
          <h2>Designs Added</h2>
          <QueryTable
            data={selectedDesigns}
            onRowSelect={(id) => {
              const design = selectedDesigns[id];
              setTableData([...tableData, design]);
              setSelectedDesigns(selectedDesigns.filter((f, i) => i !== id));
            }}
          />
          <button
            className="btn action-btn"
            style={{ width: "20%" }}
            onClick={onAddSelected}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    customers: state.customers.list,
    reseller: state.reseller.current,
    designs: state.designs.list,
  };
};

export default connect(mapStateToProps, { getDesigns, setDesigns })(
  Select_Designs
);
