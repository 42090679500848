import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SubmittedOrders.css";
import { getOrders, loadOrder } from "../../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import FilterCheckbox, {
  Status,
} from "../../components/FilterCheckbox/FilterCheckbox";
const fileDownload = require("js-file-download");

const SubmittedOrders = (props) => {
  const [localOrders, setLocalOrders] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("All");

  const [filters, setFilters] = useState({});
  let history = useHistory();

  const [sortConfig, setSortConfig] = useState({
    name: "",
    company: "",
    order_date: "",
    due_date: "",
    status: "",
  });

  useEffect(() => {
    props.getOrders();
  }, []);

  const applyFilters = (orders) => {
    return orders.map((o) => {
      let visible =
        o.status === selectedStatus ||
        (selectedStatus === "All" &&
          o.status !== "Completed" &&
          o.status !== "Canceled" &&
          o.status !== "On Hold");

      for (const [key, value] of Object.entries(filters)) {
        if (value === Status.IGNORE) continue;

        if (value === Status.INCLUDE && !o.progress[key]) {
          visible = false;
          break;
        } else if (value === Status.EXCLUDE && o.progress[key]) {
          visible = false;
          break;
        }
      }

      return { ...o, visible };
    });
  };

  useEffect(() => {
    setLocalOrders(applyFilters([...localOrders]));
  }, [filters]);

  const onExportReport = async () => {
    const res = await axios.get("/api/orders/" + selected + "/report", {
      responseType: "blob",
    });

    if (!res.data.error) {
      const order = props.orders.find((f) => f.id === selected);
      fileDownload(
        res.data,
        `10${order.id.toString().padStart(4, "0")} - ${order.job_name}.html`
      );
    }
  };
  const onSortChange = (type) => {
    const currentSort = sortConfig[type];
    let nextSort;

    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "down";
    else if (currentSort === "") nextSort = "down";

    for (var key in sortConfig) {
      if (sortConfig.hasOwnProperty(key)) {
        sortConfig[key] = key === type ? nextSort : "";
      }
    }

    setSortConfig({ ...sortConfig });
  };
  useEffect(() => {
    let orders = [...localOrders.map((o) => ({ ...o, visible: true }))];
    orders = orders.map((o) => {
      return {
        ...o,
        visible:
          o.status === selectedStatus ||
          (selectedStatus === "All" &&
            o.status !== "Completed" &&
            o.status !== "Canceled" &&
            o.status !== "On Hold"),
      };
    });
    setLocalOrders(orders);
  }, [selectedStatus]);

  useEffect(() => {
    if (props.orders?.length > 0) {
      const orders = props.orders
        .filter((o) => o.status !== "Open")
        .map((o) => {
          let checkedIn = 0;
          let checkedOut = 0;
          let total = 0;

          o.garments.map((g) => {
            g.sizes
              ?.filter((s) => s.qty && s.qty > 0)
              .map((s) => {
                if (s.checked_in) checkedIn += s.checked_in;
                if (s.checked_out) checkedOut += s.checked_out;
                total += s.qty;
              });
          });

          return {
            id: o.id,
            name: o.job_name,
            company: o.customer?.company,
            progress: {
              ...o.progress,
              check_in_string: `${checkedIn}/${total} (${(
                (checkedIn / total) *
                100
              ).toFixed(2)}%)`,
              check_out_string: `${checkedOut}/${total} (${(
                (checkedOut / total) *
                100
              ).toFixed(2)}%)`,
            },
            requested_date: o.requested_date,
            order_date: o.created_at,
            status: o.status,
            visible:
              o.status !== "Completed" &&
              o.status !== "Canceled" &&
              o.status !== "On Hold",
          };
        });

      setLocalOrders(applyFilters(orders));
    }
  }, [props.orders]);

  const toggleProgress = (id, name) => {
    let newOrders = [...localOrders];

    newOrders = newOrders.map((no) => {
      if (no.id === id)
        return {
          ...no,
          progress: {
            ...no.progress,
            [name]: no.progress[name] ? null : new Date(),
          },
          edited: true,
        };

      return no;
    });

    setLocalOrders(newOrders);
  };

  const onUpdateProgress = async () => {
    try {
      const newShipped = [];
      const editedOrders = localOrders.filter((o) => o.edited);
      for (let i = 0; i < editedOrders.length; i++) {
        const eo = editedOrders[i];
        const prevOrd = props.orders.find((o) => o.id === eo.id);

        if (eo.progress.shipped && !prevOrd?.progress.shipped)
          newShipped.push(prevOrd);
        else if (eo.progress.film_done && !prevOrd?.progress.film_done) {
          const production_value = window.prompt(
            `Please enter the production value for job #${eo.id} - ${eo.name} for ${eo.company}`
          );
          editedOrders[i].progress.production_value = production_value;
        }
      }

      if (newShipped.length > 0) {
        if (
          window.confirm(
            "Are you sure you want to mark " +
              newShipped.length +
              " order(s) as Completed?"
          )
        ) {
          for (const o of newShipped) {
            await axios.put("/api/orders/" + o.id + "/status", {
              status: "Completed",
            });
          }
        } else {
          return;
        }
      }

      const res = await axios.put("/api/orders/batch/progress", {
        orders: editedOrders,
      });
      if (res.error) throw res.error;
      toast.dark(
        "Successfully updated progress for " + editedOrders.length + " orders!"
      );

      props.getOrders();
    } catch (e) {
      toast.dark(e.message);
    }
  };

  const onCheckboxClick = (type) => {
    setFilters({
      ...filters,
      [type]:
        !filters[type] || filters[type] === Status.IGNORE
          ? Status.INCLUDE
          : filters[type] === Status.INCLUDE
          ? Status.EXCLUDE
          : Status.IGNORE,
    });
  };

  return (
    <div className="home" style={{ marginTop: 0 }}>
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: "0",
          marginBlockStart: "2rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <button
            className={selected ? "btn danger-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 5, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={async () => {
              if (
                window.confirm(
                  localOrders.find((f) => f.id === selected).status ===
                    "Canceled"
                    ? "Are you sure you want to restore this order?"
                    : "Are you sure you want to cancel this order?"
                )
              ) {
                const order = props.orders.find((f) => f.id === selected);
                if (!order) return;

                await axios.put("/api/orders/" + order.id + "/status", {
                  status:
                    order.status === "Canceled" ? "Submitted" : "Canceled",
                });

                props.getOrders();
              }
            }}
          >
            {!localOrders.find((f) => f.id === selected)
              ? "Please select one"
              : localOrders.find((f) => f.id === selected).status === "Canceled"
              ? "Restore order"
              : "Cancel order"}
          </button>
          <button
            className={selected ? "btn warning-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={async () => {
              if (
                window.confirm(
                  localOrders.find((f) => f.id === selected).status ===
                    "On Hold"
                    ? "Are you sure you want to restore this order?"
                    : "Are you sure you want to put this order on hold?"
                )
              ) {
                const order = props.orders.find((f) => f.id === selected);
                if (!order) return;

                await axios.put("/api/orders/" + order.id + "/status", {
                  status: order.status === "On Hold" ? "Approved" : "On Hold",
                });

                props.getOrders();
              }
            }}
          >
            {!localOrders.find((f) => f.id === selected)
              ? "Please select one"
              : localOrders.find((f) => f.id === selected).status === "On Hold"
              ? "Restore order"
              : `"Hold" order`}
          </button>
        </div>
        <p style={{ marginBlockEnd: "0", marginBlockStart: "0" }}>
          Current Submitted Orders
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "50%",
          }}
        >
          <button
            className={selected ? "btn action-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={() => {
              props.loadOrder(selected);
              setTimeout(
                () =>
                  (window.location.href =
                    props.reseller_code === "ALL"
                      ? "/order/summary"
                      : "/proof/" + selected),
                500
              );
            }}
          >
            {localOrders.find((f) => f.id === selected)
              ? "View proof"
              : "Please select one"}
          </button>
          <button
            className={selected ? "btn action-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={onExportReport}
          >
            {localOrders.find((f) => f.id === selected)
              ? "Export report"
              : "Please select one"}
          </button>
          <button
            className={selected ? "btn action-btn" : "btn secondary-btn"}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={!selected}
            onClick={async () => {
              const res = await axios.get(
                "/api/orders/" + selected + "/export"
              );
              fileDownload(res.data, "order_" + selected + ".csv");
            }}
          >
            {localOrders.find((f) => f.id === selected)
              ? "Export order"
              : "Please select one"}
          </button>
          <button
            className="btn action-btn"
            onClick={onUpdateProgress}
            style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
            disabled={localOrders.filter((f) => f.edited).length === 0}
          >
            Update progress
          </button>
        </div>
      </div>
      <div
        className="filters"
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "flex-end",
          width: "95%",
        }}
      >
        <select
          style={{
            width: "15%",
            margin: "2px",
            height: "3rem",
          }}
          value={selectedStatus}
          onChange={(e) => {
            setFilters({});
            setSelectedStatus(e.target.value);
          }}
        >
          <option value="All">All</option>
          <option value="Submitted">Submitted</option>
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Completed">Completed</option>
          <option value="Canceled">Canceled</option>
          <option value="On Hold">On Hold</option>
        </select>
      </div>
      <div className="content">
        <div className="query-table reports-table">
          <table>
            <thead>
              <tr
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#ddd",
                }}
              >
                <th
                  style={{ cursor: "pointer", userSelect: "none" }}
                  onClick={() => {
                    onSortChange("name");

                    setLocalOrders(
                      [...localOrders].sort((a, b) =>
                        sortConfig.name === ""
                          ? a
                          : sortConfig.name === "up"
                          ? b.name.localeCompare(a.name)
                          : a.name.localeCompare(b.name)
                      )
                    );
                  }}
                >
                  Order Name&nbsp;
                  <i className={`fa fa-sort`} />
                </th>

                <th
                  style={{ cursor: "pointer", userSelect: "none" }}
                  onClick={() => {
                    onSortChange("due_date");
                    setLocalOrders(
                      [...localOrders].sort((a, b) =>
                        sortConfig.due_date === ""
                          ? a
                          : sortConfig.due_date === "up"
                          ? new Date(b.requested_date) -
                            new Date(a.requested_date)
                          : new Date(a.requested_date) -
                            new Date(b.requested_date)
                      )
                    );
                  }}
                >
                  Due Date&nbsp; <i className={`fa fa-sort`} />
                </th>
                <th>
                  <div className="checkbox-container">
                    Proof Sent
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("proof_sent")}
                      status={filters["proof_sent"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Approved
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("proof_approved")}
                      status={filters["proof_approved"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Film
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("film_done")}
                      status={filters["film_done"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Check In
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("checked_in")}
                      status={filters["checked_in"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Darkroom
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("darkroom_done")}
                      status={filters["darkroom_done"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Kitting
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("kitting")}
                      status={filters["kitting"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Production
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("in_production")}
                      status={filters["in_production"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Check Out
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("checked_out")}
                      status={filters["checked_out"]}
                    />
                  </div>
                </th>
                <th>
                  <div className="checkbox-container">
                    Shipped
                    <FilterCheckbox
                      onClick={(e) => onCheckboxClick("shipped")}
                      status={filters["shipped"]}
                    />
                  </div>
                </th>
                <th
                  style={{ cursor: "pointer", userSelect: "none" }}
                  onClick={() => {
                    onSortChange("status");
                    setLocalOrders(
                      [...localOrders].sort((a, b) =>
                        sortConfig.status === ""
                          ? a
                          : sortConfig.status === "up"
                          ? b.status.localeCompare(a.status)
                          : a.status.localeCompare(b.status)
                      )
                    );
                  }}
                >
                  Status&nbsp; <i className={`fa fa-sort`} />
                </th>
              </tr>
            </thead>
            <tbody>
              {[...localOrders]
                ?.filter((o) => o.visible)
                .map((o, i) => {
                  return (
                    <tr
                      className={
                        o.edited
                          ? "row-pending"
                          : selected === o.id
                          ? "row-selected"
                          : ""
                      }
                    >
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          selected === o.id
                            ? setSelected(null)
                            : setSelected(o.id)
                        }
                      >
                        {o.name}
                      </td>
                      <td>{new Date(o.requested_date).toLocaleDateString()}</td>
                      <td
                        className={o.progress.proof_sent ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "proof_sent")}
                      >
                        {o.progress.proof_sent &&
                          new Date(o.progress.proof_sent).toLocaleDateString()}
                      </td>
                      <td
                        className={o.progress.proof_approved ? "completed" : ""}
                        onClick={() => {
                          //  toggleProgress(o.id, "approved")
                        }}
                      >
                        {o.progress.proof_approved &&
                          new Date(
                            o.progress.proof_approved
                          ).toLocaleDateString()}
                      </td>
                      <td
                        className={o.progress.film_done ? "completed" : ""}
                        onClick={() => {
                          toggleProgress(o.id, "film_done");
                        }}
                      >
                        {o.progress.film_done &&
                          new Date(o.progress.film_done).toLocaleDateString()}
                      </td>
                      <td
                        className={o.progress.checked_in ? "completed" : ""}
                        style={{ textAlign: "center" }}
                        onClick={() =>
                          history.push({
                            pathname: "/reports/check-in",
                            search: "",
                            state: { order_id: o.id },
                          })
                        }
                      >
                        {o.progress.checked_in &&
                          new Date(o.progress.checked_in).toLocaleDateString()}
                        <br />
                        {o.progress.check_in_string}
                      </td>
                      <td
                        className={o.progress.darkroom_done ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "darkroom_done")}
                      >
                        {o.progress.darkroom_done &&
                          new Date(
                            o.progress.darkroom_done
                          ).toLocaleDateString()}
                      </td>
                      <td
                        className={o.progress.kitting ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "kitting")}
                      >
                        {o.progress.kitting &&
                          new Date(o.progress.kitting).toLocaleDateString()}
                      </td>
                      <td
                        className={o.progress.in_production ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "in_production")}
                      >
                        {o.progress.in_production &&
                          new Date(
                            o.progress.in_production
                          ).toLocaleDateString()}
                      </td>

                      <td
                        className={o.progress.checked_out ? "completed" : ""}
                        style={{ textAlign: "center" }}
                        onClick={() =>
                          history.push({
                            pathname: "/reports/check-out",
                            search: "",
                            state: { order_id: o.id },
                          })
                        }
                      >
                        {o.progress.checked_out &&
                          new Date(o.progress.checked_out).toLocaleDateString()}
                        <br />
                        {o.progress.check_out_string}
                      </td>
                      <td
                        className={o.progress.shipped ? "completed" : ""}
                        onClick={() => toggleProgress(o.id, "shipped")}
                      >
                        {o.progress.shipped &&
                          new Date(o.progress.shipped).toLocaleDateString()}
                      </td>
                      <td style={{ textAlign: "center" }}>{o.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders.list,
    reseller_code: state.reseller.current.code,
  };
};
export default connect(mapStateToProps, { getOrders, loadOrder })(
  SubmittedOrders
);
