const axios = require("axios");

export const getDesigns = () => {
  return axios.get("/api/designs");
};

export const getDesign = (id) => {
  return axios.get("/api/designs?id=" + id);
};
export const pendingDesign = (id) => {
  return axios.patch("/api/designs", { id, status: "Pending" });
};
export const approveDesign = (id) => {
  return axios.patch("/api/designs", { id, status: "Approved" });
};
export const archiveDesign = (id) => {
  return axios.patch("/api/designs", { id, status: "Archived" });
};
export const submitDesign = (data) => {
  return axios.post("/api/designs", data);
};
export const updateDesign = (id, data) => {
  return axios.patch("/api/designs/"+id, data);
};
