import axios from "axios";

export const getOrders = () => {
  return axios.get("/api/orders");
};


export const getOrder = (id) => {
  return axios.get("/api/orders/"+id);
};
