import React from "react";
import Steps from "rc-steps"
import { useHistory } from "react-router-dom";
const OrderSteps = ({currentStep}) => {
  const history = useHistory();
  return (
    <Steps current={currentStep}>
      <Steps.Step
        onClick={(e) => {
          history.push("/order");
        }}
        title="Customers"
      />
      <Steps.Step onClick={(e) => {
          history.push("/order/designs");
        }}
        title="Designs" />
      <Steps.Step onClick={(e) => {
          history.push("/order/distributor");
        }}
        title="Garments" />
      <Steps.Step onClick={(e) => {
          history.push("/order/placements");
        }}
        title="Placements" />
      <Steps.Step 
      onClick={(e) => {
        history.push("/order/summary");
      }}
      title="Summary" />
      {/* <Steps.Step title="Reports" /> */}
    </Steps>
  );
};

export default OrderSteps;