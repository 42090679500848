import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import QueryTable from "../../components/QueryTable/QueryTable";
import { useHistory, Prompt } from "react-router-dom";
import { setReseller, getResellers, getDesigns } from "../../actions";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Select_Customer = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: props.reseller });
  const history = useHistory();

  const [tableData, setTableData] = useState([]);

  const onRowSelect = (id) => {
    let dataCopy = [...tableData];
    dataCopy = dataCopy.map((d, i) => {
      if (id !== i) return { ...d, selected: false };
      return d;
    });
    dataCopy[id].selected = !dataCopy[id].selected;
    props.setReseller(dataCopy[id].selected ? props.resellers[id] : {});
    reset(dataCopy[id].selected ? props.resellers[id] : {});
    setTableData(dataCopy);
  };

  useEffect(() => {
    props.getResellers();
    props.getDesigns();
    reset({});
    // props.setReseller({});
  }, []);

  useEffect(() => {
    const data = props.resellers.map((c) => {
      return {
        name: c.code,
        value: c.company,
        full_data: c,
        selected: false,
      };
    });
    setTableData(data);
  }, []);

  const onSubmit = async (data) => {
    const found = tableData.find((td) => td.selected);
    if (found) {
      const res = await axios.patch("/api/resellers/" + found.name, {
        ...data,
        address_id: found.full_data.address_id,
      });
      if (res.data.error) {
        toast.dark(res.data.error);
        props.getResellers();
      }
      props.setReseller(res.data.reseller);
    } else {
      const res = await axios.post("/api/resellers", data);
      if (res.data.error) {
        toast.dark(res.data.error);
        return;
      }
      props.setReseller(res.data.reseller);
      props.getResellers();
    }

    setTimeout(() => history.push("/"), 500);
  };

  return (
    <div className="orders">
      <div
        className="title"
        style={{
          display: "flex",
          marginBlockEnd: 0,
          marginBlockStart: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => history.goBack()}
          className="btn secondary-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Back
        </button>
        <div>Setup new reseller</div>
        <button
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
          className="btn action-btn"
          style={{ padding: 0, margin: 0, width: "10rem", height: "3rem" }}
        >
          Save
        </button>
      </div>
      <div className="content">
        <div className="left">
          <h2>Reseller Info</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="id" {...register("id")} />
            <input
              type="text"
              name="name"
              placeholder="Reseller Code (ex. APPS)"
              {...register("reseller_code")}
            />
            <input
              type="text"
              name="company"
              placeholder="company"
              {...register("company")}
            />

            <input
              type="text"
              name="contact_1"
              placeholder="Contact 1"
              {...register("contact_1")}
            />
            <input
              type="text"
              name="contact_2"
              placeholder="Contact 2"
              {...register("contact_2")}
            />
            <input
              type="text"
              name="address_1"
              placeholder="Address 1"
              {...register("address_1")}
            />
            <input
              type="text"
              name="address_2"
              placeholder="Address 2"
              {...register("address_2")}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              {...register("phone")}
            />
            <input
              type="text"
              name="email"
              placeholder="E-mail"
              {...register("email")}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              {...register("city")}
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              {...register("state")}
            />
            <input
              type="text"
              name="zip"
              placeholder="ZIP"
              {...register("zip")}
            />
            {/* <button className="btn action-btn" type="submit">
              Add/Next
            </button> */}
          </form>
        </div>
        <div className="right">
          <h2>Select from existing</h2>

          <QueryTable data={tableData} onRowSelect={onRowSelect} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.orders.current,
    reseller: state.reseller.current,
    resellers: state.reseller.list,
  };
};

export default connect(mapStateToProps, {
  setReseller,
  getDesigns,
  getResellers,
})(Select_Customer);
