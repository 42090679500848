import React, { useState, useEffect } from "react";
import {
  getDesign,
  approveDesign,
  archiveDesign,
  pendingDesign,
} from "../../api";
import { useParams, useHistory } from "react-router-dom";
const Design = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [design, setDesign] = useState(null);

  useEffect(() => {
    fetchDesign();
  }, []);

  const fetchDesign = async () => {
    const d = await getDesign(id);
    setDesign(d.data[0]);
  };
  const onApprove = async () => {
    await approveDesign(id);
    history.push("/designs");
  };
  const onArchive = async () => {
    await archiveDesign(id);
    history.push("/designs");
  };
  const onPending = async () => {
    await pendingDesign(id);
    history.push("/designs");
  };
  return (
    <div className="home" style={{ marginTop: 0 }}>
      <h3 className="title">
        Design {design ? design.art_id ?? design.id : "not found"}
      </h3>
      {design && (
        <div className="content">
          <div className="left">
            <h2>Details</h2>
            <div>
              <h3>Status: </h3>
              {design.status}
            </div>
            <div>
              <h3>Customer: </h3> {design.company}
            </div>
            <div>
              <h3>Name: </h3>
              {design.name}
            </div>
            <div>
              <h3>Width: </h3>
              {design.width}
            </div>
            <div>
              <h3>Height: </h3>
              {design.height}
            </div>
            <div>
              <h3>Ink/Thread Colors:</h3>
              <p>{design.ink_color_1}</p>
              <p>{design.ink_color_2}</p>
              <p>{design.ink_color_3}</p>
              <p>{design.ink_color_4}</p>
              <p>{design.ink_color_5}</p>
              <p>{design.ink_color_6}</p>
              <p>{design.ink_color_7}</p>
              <p>{design.ink_color_8}</p>
            </div>
            <div>
              {design.status === "Submitted" && (
                <button className="btn action-btn" onClick={onPending}>
                  Mark as pending
                </button>
              )}
              {design.status === "Pending" && (
                <button className="btn action-btn" onClick={onApprove}>
                  Approve
                </button>
              )}
              {design.status === "Approved" && (
                <button className="btn secondary-btn" onClick={onArchive}>
                  Archive
                </button>
              )}
            </div>
          </div>
          <div className="right">
            <h2>Preview</h2>
            <img
              src={"/api/designs/" + design.id + ".png"}
              className="preview-icon"
              style={{ width: "350px" }}
              alt="Design preview"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Design;
