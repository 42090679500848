import React, { useEffect } from "react";
import "./SizeList.css";
import { connect } from "react-redux";
const SizeList = (props) => {
  const { sizes, setSizes, onNewRow } = props;
  const handleChange = (e, idx) => {
    const sizesCopy = [...sizes];

    sizesCopy[idx][e.target.name] = e.target.value;
    setSizes(sizesCopy);
  };

  useEffect(() => {
    console.log(`sizes`, sizes);
  }, [sizes]);
  return (
    <div style={{ overflow: "auto", maxHeight: "85%" }}>
     
      {sizes?.map((s, idx) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <input
              type="text"
              name="size"
              className="size-input"
              value={sizes[idx].size}
              disabled={props.distributor!=="Manual"}
              onChange={(e) => handleChange(e, idx)}
            />
            <input
              className="size-input"
              type="text"
              name="qty"
              placeholder="Qty"
              value={sizes[idx].qty}
              onChange={(e) => handleChange(e, idx)}
              style={{ width: "20%" }}
            />
            <input
              className="size-input"
              type="text"
              name="price"
              placeholder="Price"
              value={sizes[idx].price}
              onChange={(e) => handleChange(e, idx)}
              style={{ width: "20%" }}
            />
          </div>
        );
      })}
       {props.distributor==="Manual" && (
        <button
          className={"btn action-btn"}
          style={{ padding: 0, margin: 0, width: "6rem", height: "3rem" }}
          onClick={onNewRow}
        >
          Add Size
        </button>
      )}
    </div>
  );
};

//TO-DO: Remove this 
const mapStateToProps = (state) => {
  return {
    distributor:
      state.orders.current.garments[state.orders.current.active_garment] ? state.orders.current.garments[state.orders.current.active_garment].distributor : "Manual"
  };
};
export default connect(mapStateToProps, {})(SizeList);
